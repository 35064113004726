import React, { useEffect, useRef, useState } from "react";
import "./CreateTicketForm.scss";
import {
  fileupload,
  getMethod,
  postMethod,
} from "../../API_SERVICES/ApiServices";
import { Button, Modal, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { closeModal } from "./ModalSlice";
import moment from "moment";
import { Alert, Dialog, DialogActions, DialogContent, DialogTitle, Snackbar, TextField } from "@mui/material";
import Swal from "sweetalert2";
import { AddCircleOutline, Password } from "@mui/icons-material";
import { API_URL } from "./API_Services/ServiceLayer";

export const calculateEndTime = (
  StartDateAndTime,
  isHours,
  responseTime,
  mgStart,
  mgEnd
) => {
  let DateIssueCreated = new Date(StartDateAndTime);
  let TSIssueCreated = new Date(DateIssueCreated).getTime();
  const [mgStartHours, mgStartMinutes, mgStartSeconds] = mgStart.split(":");
  const [mgEndHours, mgEndMinutes, mgEndSeconds] = mgEnd.split(":");

  const TSMgTmrwStart = new Date(
    2023,
    0,
    2,
    +mgStartHours,
    +mgStartMinutes,
    +mgStartSeconds
  );
  const TSMgStart = new Date(
    2023,
    0,
    1,
    +mgStartHours,
    +mgStartMinutes,
    +mgStartSeconds
  );
  const TSMgEnd = new Date(
    2023,
    0,
    1,
    +mgEndHours,
    +mgEndMinutes,
    +mgEndSeconds
  );
  const cutOutTime = Math.abs(TSMgEnd - TSMgTmrwStart);
  const workingTime = Math.abs(TSMgEnd - TSMgStart);

  const DateIssueWorkEnd = new Date(
    DateIssueCreated.getFullYear(),
    DateIssueCreated.getMonth(),
    DateIssueCreated.getDate(),
    +mgEndHours,
    +mgEndMinutes,
    +mgEndSeconds
  );
  const TSIssueWorkEnd = DateIssueWorkEnd.getTime();
  // console.log("sri", new Date(StartDateAndTime),
  //   isHours,
  //   responseTime,
  //   mgStart,
  //   mgEnd,);
  //calculate based on hours
  if (isHours === 1) {
    // console.log("Hours");
    const [splittedResponseHours, splittedResponseMinutes] =
      responseTime.split(":");
    const TSIssueEnd =
      TSIssueCreated +
      (+splittedResponseHours + +splittedResponseMinutes / 60) * 60 * 60 * 1000;
    //----->check issue creation after or before workin hour
    if (
      DateIssueCreated.getHours() <= +mgStartHours - 1 ||
      DateIssueCreated.getHours() >= +mgEndHours
    ) {
      //adjust date based on time
      let fixDate =
        DateIssueCreated.getHours() >= 0 && DateIssueCreated.getHours() < 10
          ? DateIssueCreated.getDate()
          : DateIssueCreated.getDate() + 1;
      // console.log("fix", DateIssueCreated.getDate(), fixDate);
      let tempDate = new Date(
        DateIssueCreated.getFullYear(),
        DateIssueCreated.getMonth(),
        fixDate,
        +mgStartHours,
        +mgStartMinutes,
        +mgStartSeconds
      );
      //----->eliminate sundays
      if (tempDate.getDay() === 0) {
        tempDate.setDate(tempDate.getDate() + 1);
      }
      let TSTempDate = tempDate.getTime();
      TSTempDate =
        TSTempDate +
        (+splittedResponseHours + +splittedResponseMinutes / 60) *
          60 *
          60 *
          1000;
      // console.log("laksh", new Date(TSTempDate));
      const temp = new Date(TSTempDate);
      return temp;
    } else if (TSIssueWorkEnd >= TSIssueEnd) {
      //----->check completes in before working hour end
      // console.log("laksh", "smaller",);
      const tempDate = new Date(TSIssueEnd);
      const temp =
        tempDate.getDay() === 0
          ? new Date(tempDate.setDate(tempDate.getDate() + 1))
          : tempDate;
      return temp;
    } else if (TSIssueWorkEnd < TSIssueEnd) {
      //----->check completes in after working hour end
      // console.log("laksh", "larger", new Date(TSIssueEnd));
      const tempDate = new Date(
        TSIssueWorkEnd + cutOutTime + (TSIssueEnd - TSIssueWorkEnd)
      );
      const temp =
        tempDate.getDay() === 0
          ? new Date(tempDate.setDate(tempDate.getDate() + 1))
          : tempDate;
      return temp;
    }
  } else if (isHours === 0) {
    // console.log("Days");
    // let TSIssueEnd = TSIssueCreated + workingTime * responseTime;
    // let DateIssueEnd = new Date(TSIssueEnd);
    //1.eliminate non working hours when creating issue
    if (
      DateIssueCreated.getHours() <= +mgStartHours - 1 ||
      DateIssueCreated.getHours() >= +mgEndHours
    ) {
      // console.log("non-working_hours");
      //adjust date based on time
      let fixDate =
        DateIssueCreated.getHours() >= 0 && DateIssueCreated.getHours() < 10
          ? DateIssueCreated.getDate()
          : DateIssueCreated.getDate() + 1;
      // console.log("fix", DateIssueCreated.getDate(), fixDate);
      let tempDate = new Date(
        DateIssueCreated.getFullYear(),
        DateIssueCreated.getMonth(),
        fixDate,
        +mgStartHours,
        +mgStartMinutes,
        +mgStartSeconds
      );
      //----->eliminate sundays
      if (tempDate.getDay() === 0) {
        tempDate = new Date(tempDate.setDate(tempDate.getDate() + 1));
      }
      let TSTempDate = tempDate.getTime();
      // console.log("b", tempDate);
      tempDate = new Date(TSTempDate + workingTime);
      // console.log("a", tempDate);
      let i = 0;
      for (let index = 1; index <= responseTime; index++) {
        if (i != 0) {
          tempDate = new Date(tempDate.setDate(tempDate.getDate() + i));
        }
        i = i + 1;
        // console.log(i, tempDate);
        if (tempDate.getDay() === 0) {
          tempDate = new Date(tempDate.setDate(tempDate.getDate() + 1));
        }
      }
      // console.log(tempDate, TSTempDate);
      return tempDate;
    }
    //2.in for loop check according to the issue end
    else {
      let tempDate = new Date(
        DateIssueCreated.getFullYear(),
        DateIssueCreated.getMonth(),
        DateIssueCreated.getDate(),
        +mgStartHours,
        +mgStartMinutes,
        +mgStartSeconds
      );
      const gap = DateIssueCreated - tempDate;
      //----->eliminate sundays
      if (tempDate.getDay() === 0) {
        tempDate = new Date(tempDate.setDate(tempDate.getDate() + 1));
      }
      let TSTempDate = tempDate.getTime();
      // console.log("b", tempDate);
      // tempDate = new Date(TSTempDate + workingTime)
      // console.log("a", tempDate);
      let i = 0;
      for (let index = 1; index <= responseTime; index++) {
        //new
        tempDate = new Date(tempDate.setDate(tempDate.getDate() + 1));
        //eliminate sundays
        if (tempDate.getDay() === 0) {
          tempDate = new Date(tempDate.setDate(tempDate.getDate() + 1));
        }
        //old
        // if (i != 0) { tempDate = new Date(tempDate.setDate(tempDate.getDate() + i)) };
        // i = i + 1;
        // console.log(i, tempDate);
        // if (tempDate.getDay() === 0) {
        //   tempDate = new Date(tempDate.setDate(tempDate.getDate() + 1));
        // }
      }
      // console.log("before gap", tempDate);
      tempDate = new Date(tempDate.getTime() + gap);
      // console.log("after gap", tempDate);
      return tempDate;
      // console.log(tempDate, TSTempDate);
    }
    // else if (TSIssueWorkEnd >= TSIssueEnd) {
    //   console.log("laksh", "smaller",);
    //   // console.log("laksh", "smaller", new Date(TSIssueWorkEnd), new Date(TSIssueEnd), cutOutTime, mgEndHours);
    //   // console.log("laksh", DateIssueEnd);
    //   const tempDate = new Date(TSIssueEnd);
    //   const temp = tempDate.getDay() === 0 ? new Date(tempDate.setDate(tempDate.getDate() + 1)) : tempDate;
    //   // console.log("sri", tempDate.getDay());
    //   return temp;
    //   // return new Date(TSIssueEnd)

    // } else if (TSIssueWorkEnd < TSIssueEnd) {
    //   console.log("laksh", "larger", DateIssueWorkEnd, DateIssueEnd);
    //   let milliseconds = workingTime * responseTime;
    //   let i = 0;
    //   while (milliseconds > 0) {
    //     console.log(milliseconds);
    //     let DateTempIssueWorkEnd = DateIssueWorkEnd;
    //     let TSTempIssueWorkEnd = DateTempIssueWorkEnd.getTime();
    //     let DateTempIssueCreated = DateIssueCreated;
    //     let TSTempIssueCreated = DateTempIssueCreated.getTime();
    //     let gap = TSTempIssueWorkEnd - TSTempIssueCreated;
    //     milliseconds -= gap;
    //     console.log(milliseconds, gap);
    //     if (milliseconds > 0) {
    //       //reset to next day start time
    //       DateTempIssueCreated = new Date(DateTempIssueCreated.getFullYear(), DateTempIssueCreated.getMonth(), DateTempIssueCreated.getDate() + 1, +mgStartHours, +mgStartMinutes, mgStartSeconds);
    //       if (DateTempIssueCreated.getDay() === 0) {
    //         DateTempIssueCreated = new Date(DateTempIssueCreated.setDate(DateTempIssueCreated.getDate() + 1));
    //       }
    //       TSTempIssueWorkEnd = DateTempIssueCreated.getTime();
    //       //reset to next day end time
    //       DateTempIssueWorkEnd = new Date(DateTempIssueCreated.getFullYear(), DateTempIssueCreated.getMonth(), DateTempIssueCreated.getDate(),
    //         +mgEndHours, +mgEndMinutes, +mgEndSeconds);
    //       TSTempIssueWorkEnd = DateTempIssueWorkEnd.getTime();
    //       console.log(i, DateTempIssueCreated, DateTempIssueWorkEnd);
    //       i++;
    //     }
    //     //  else {
    //     //   console.log(DateTempIssueCreated, DateTempIssueWorkEnd);
    //     // }
    //     // milliseconds = 0;
    //   }
    //   milliseconds = 0;
    //   let DateStart = new Date(DateIssueCreated.setDate(DateIssueCreated.getDate() + 1));
    //   DateStart.setHours(+mgStartHours);
    //   DateStart.setMinutes(+mgStartMinutes);
    //   DateStart.setSeconds(+mgStartSeconds);
    //   let TSStart = DateStart.getTime();
    //   const gap = TSIssueEnd - TSIssueWorkEnd;
    //   console.log(gap, new Date(TSStart + gap));

    //   for (let i = 1; i <= responseTime; i++) {

    //     // console.log("laksh", i, DateIssueCreated, DateIssueEnd, new Date((TSIssueWorkEnd + cutOutTime + (TSIssueEnd - TSIssueWorkEnd))));
    //     // console.log("update", i, new Date((TSIssueWorkEnd + cutOutTime + (TSIssueEnd - TSIssueWorkEnd))));
    //     // DateIssueEnd = new Date((TSIssueWorkEnd + cutOutTime + (TSIssueEnd - TSIssueWorkEnd)));
    //     // TSIssueEnd = DateIssueEnd.getTime();
    //     // console.log("laksh", "update", DateIssueEnd, new Date((TSIssueWorkEnd + cutOutTime + (TSIssueEnd - TSIssueWorkEnd))));
    //     // TSIssueEnd = new Date((TSIssueWorkEnd + cutOutTime + (TSIssueEnd - TSIssueWorkEnd))).getTime();
    //     //new
    //     let DateUpIssueCreated = new Date((TSIssueWorkEnd + cutOutTime + (TSIssueEnd - TSIssueWorkEnd)));
    //     let TSUpIssueCreated = DateUpIssueCreated.getTime();
    //     TSIssueEnd = TSUpIssueCreated + workingTime;
    //     DateIssueEnd = new Date(TSIssueEnd);

    //     //sun remove
    //     // let DateUpIssueCreated = new Date((TSIssueWorkEnd + cutOutTime + (TSIssueEnd - TSIssueWorkEnd)));
    //     // let tempDate = DateUpIssueCreated.getDay() === 6 ? new Date(DateUpIssueCreated.setDate(DateUpIssueCreated.getDate() + 1)) : DateUpIssueCreated;
    //     // let TSUpIssueCreated = tempDate.getTime();
    //     // TSIssueEnd = TSUpIssueCreated + workingTime;
    //     // DateIssueEnd = new Date(TSIssueEnd);
    //     // TSIssueEnd = 1688061057000;
    //     // const DateIssueEnd = new Date(TSIssueEnd);
    //     // console.log("laksh", new Date(TSUpIssueCreated + workingTime), TSIssueWorkEnd, TSIssueWorkEnd < TSIssueEnd);

    //   }
    //   // console.log("laksh", "larger",);
    //   // console.log("laksh", "larger", new Date(TSIssueWorkEnd), new Date(TSIssueEnd), cutOutTime, mgEndHours);
    //   // console.log("laksh", DateIssueCreated, DateIssueEnd, DateIssueWorkEnd, new Date((TSIssueWorkEnd + cutOutTime + (TSIssueEnd - TSIssueWorkEnd))));
    //   const tempDate = new Date((TSIssueWorkEnd + cutOutTime + (TSIssueEnd - TSIssueWorkEnd)));
    //   const temp = tempDate.getDay() === 0 ? new Date(tempDate.setDate(tempDate.getDate() + 1)) : tempDate;
    //   return temp;
    //   // console.log("sri", temp);
    //   // return new Date((TSIssueWorkEnd + cutOutTime + (TSIssueEnd - TSIssueWorkEnd)))
    // }
  }
};


function CreateTicket() {
  const [responseEndTime, setResponseEndTime] = useState(null);
  const [resolutionEndTime, setResolutionEndTime] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const [formData, setFormData] = useState({
    issue_title: "",
    description: "",
    issue_category_id: null,
    issue_sub_category_id: null,
    role_id: null,
    property_id: null,
    user_id: null,
    corporate_id: null,
    resolved_by: null,
    resolved_at: null,
    status: 1,
    created_by: null,
  });

  const [employeeName, setEmployeeName] = useState('');
  const [employeeEmail, setEmployeeEmail] = useState('');
  const [employeeNumber, setEmployeeNumber] = useState('');


  const createdBy = JSON.parse(localStorage.getItem("user"))["id"];

  const issueStatusMaster = [
    { id: 1, label: "Open" },
    { id: 2, label: "Assigned To Staff" },
    { id: 3, label: "In Progress" },
    { id: 4, label: "Resolved" },
    { id: 5, label: "Re-Open" },
    { id: 6, label: "Close" },
  ];

  const typeOfUser = useRef();

  const [usersList, setUsersList] = useState([]);
  const [corporateUsersList, setCorporateUsersList] = useState([]);
  const [propertyList, setPropertyList] = useState([]);
  const [filteredCategory, setFilteredCategory] = useState({});

  const [networkErrors, setNetworkErrors] = useState({
    issueCategoryLoadingErr: "",
    propertyListEmptyErr: "Select Property",
  });
  const dispatch = useDispatch();
  const AcsRightsUser = localStorage.getItem('acsRightUser');

  const[loggedPersonProperty,setLoggedPersonProperty]=useState([])
  const [issueCategoryList, setIssueCategoryList] = useState([]);
  const [issueSubCategoryList, setIssueSubCategoryList] = useState([]);
  const uploadRef = useRef();
  const [file, setFiles] = useState([]);
  const [issueImages, setIssueImages] = useState([]);
  const [fileError, setFileError] = useState();
  const [imgPathName, setImagePathName] = useState();
  const [filesName, setFilesName] = useState([]);




  const handleFilesChange = (e, ref) => {
    let type = e.target.files[0].type.split("/");
    let size = e.target.files[0].size;
    let path = e.target.files[0].name;
    let name = e.target.name;

    if (name === "issue_image") {
      // console.log("Pathname", path);
      setImagePathName(path);

      if (type[1] == "png" || type[1] == "jpg" || type[1] == "jpeg") {
        if (size >= 102400 && size < 1048576) {
          let files = e.target.files;
          let files_array = [];
          let files_name = [];
          let files_data = [];

          if (file) {
            files_array = file;
          }

          if (issueImages) {
            files_data = issueImages;
          }

          files_name = filesName;

          for (let i = 0; i < files.length; i++) {
            // console.log("Array Files", files[i]);

            const fileExists = files_data.some(
              (file) => file.name === files[i].name
            );

            if (!fileExists) {
              files_array.push(URL.createObjectURL(files[i]));
              // console.log("File array push", files_array);
              setFiles(files_array);
              files_data.push(files[i]);
              // console.log("files_data", files_data);

              files_name.push(e.target.files[i].name);
              setFileError("");
              setIssueImages(files_data);

              setFilesName(files_name);
            }
          }
        } else if (size <= 102400) {
          setFileError("File should be greater than 100Kb");
        } else if (size >= 1048576) {
          setFileError("File should not be greater than 1Mb");
        }
      } else {
        setFileError("Invalid Format");
      }
      ref.current.value = "";
    }
  };

  const handleImageUrlDelete = (index) => {
    let data = [];

    data =
      file &&
      file.filter((value, key) => {
        if (key != index) {
          data.push(value);
        }
        setFiles(data);
      });

    let imgarray = [];
    imgarray =
      issueImages &&
      issueImages.filter((value, key) => {
        if (key != index) {
          data.push(value);
        }
        setIssueImages(data);
      });
  };

  useEffect(() => {
    const controller = new AbortController();
    getMethod("issue-management/api/v1/issue-category", controller.signal)
      .then((res) => {
        setIssueCategoryList(res?.data?.data);
      })
      .catch((err) =>
        setNetworkErrors({
          ...networkErrors,
          issueCategoryLoadingErr: err?.data?.message,
        })
      );

    return () => {
      controller.abort();
    };
  }, []);

  const getUsersList = async (value) => {
    const controller = new AbortController();
    await getMethod("user-management/api/v1/user", controller.signal).then(
      (res) => {
        if (res?.data?.status) {
          let members = res?.data?.data.filter((ele) =>
            ele.roles.some((moon) => moon.pivot.role_id == value)
          );
          let staffs = res?.data?.data.filter((ele) =>
            ele.roles.some((moon) => moon.pivot.role_id == value)
          );

          const filteredData =
            value == 10
              ? staffs
                  ?.sort((a, b) => b.id - a.id)
                  .filter((stat) => stat.status === 1)
              : members
                  ?.sort((a, b) => b.id - a.id)
                  .filter((stat) => stat.status === 1);

          // console.log("apidata", filteredData);
          setUsersList(filteredData);
        }
      }
    );
    return () => {
      controller.abort();
    };
  };


  const TOgetUsersRoles = JSON.parse(localStorage.getItem('user'));

  // const allowedRoles = ["Admin", "Super Admin", "Sub Admin"];
  // const userRoles = TOgetUsersRoles.roles.map((c) => c.role_name);
  
  const myUserOrg= TOgetUsersRoles?.user_org_depts?.map((c)=>c);

  const myUserOrgDept=myUserOrg?.map((d)=>d?.organization_department_role)

  const MyuserOrgDepRoles=myUserOrgDept?.map((d)=>d.dep_role_name)

  // console.log(MyuserOrgDepRoles,"filteredDeptRole")

  const allowedRoles = ["Admin", "Super Admin", "Sub Admin"];
  

  const userRoles = TOgetUsersRoles.roles.map((c) => c.role_name);


    const hasAccess = userRoles.some((role) => allowedRoles.includes(role)) 
    // || 
    //                   MyuserOrgDepRoles.some((role) => allowedRoles.includes(role));
     

  const filteredStaffPropertyList = hasAccess?propertyList:propertyList.filter(property => 
    loggedPersonProperty.includes(property?.id)
  );

// console.log(filteredStaffPropertyList,"hhhhhhh");

  const getCorporateList = async () => {
    await getMethod("api/allcorporate?limit=1000").then((res) => {
      if (res?.data?.status === "success") {
        if (formData?.role_id == 7)
          setCorporateUsersList(res?.data?.data?.filter((d) => d.status === 1));
        setUsersList(res?.data?.data?.filter((d) => d.status === 1));
      }
    });
  };
  console.log(propertyList,"properylistttt");
  useEffect(() => {
    getCorporateList();
  }, [formData?.role_id == 7]);

  const getAllProperties = async () => {
    setPropertyList([]);
    setNetworkErrors({
      ...networkErrors,
      propertyListEmptyErr: "Loading Properties",
    });
    await getMethod("issue-management/api/v1/property-list")
      .then((res) => {
        // console.log("All properties", res?.data);
        if (res?.data?.status) {
         
          
          // Now you can set the filtered list
          setPropertyList(res?.data?.data);
          setNetworkErrors({
            ...networkErrors,
            propertyListEmptyErr: "Select Property",
          });
        }
      })
      .catch((err) => {
        // console.log(err?.response?.data?.message);
        setNetworkErrors({
          ...networkErrors,
          propertyListEmptyErr: err?.response?.data?.message,
        });
      });
  };

  const getPropertyList = async (propertyID) => {
    const controller = new AbortController();
    await getMethod("issue-management/api/v1/property-list", controller.signal)
      .then((res) => {
        // console.log("Property", res);
        if (res?.data?.status) {
          setPropertyList(res?.data?.data);
          setNetworkErrors({
            ...networkErrors,
            propertyListEmptyErr: "Select Property",
          });
        } else {
          setNetworkErrors({
            ...networkErrors,
            propertyListEmptyErr: res?.data?.message,
          });
          setPropertyList([]);
        }
      })
      .catch((err) => {
        // console.log(err?.response?.data?.message);
        setNetworkErrors({
          ...networkErrors,
          propertyListEmptyErr: err?.response?.data?.message,
        });
      });
    return () => {
      controller.abort();
    };
  };
  const getCorporatePropertyList = (corporateID) => {
    getMethod(
      "visitor-management/api/v1/get-corporate-properties/" + corporateID
    )
      .then((res) => {
        // console.log("Corporate propety list", res?.data?.data);
        if (res?.data?.status) {
          setPropertyList(res?.data?.data);
          setNetworkErrors({
            ...networkErrors,
            propertyListEmptyErr: "Select Property",
          });
        } else {
          setSnackbarOpen(true);
          setNetworkErrors({
            ...networkErrors,
            // propertyListEmptyErr: res?.data?.message,
            propertyListEmptyErr: "No property is linked to corporate",
          });
          setPropertyList([]);
          return setTimeout(function () {
            setSnackbarOpen(false);
          }, 4000);
        }
      })
      .catch((err) => {
        // console.log(err?.response?.data?.message);
        setNetworkErrors({
          ...networkErrors,
          propertyListEmptyErr: err?.response?.data?.message,
        });
      });
  };

  const getCorporatePropertyEmployee = (propertyId) => {
    // console.log("corporate employee", propertyId);
    postMethod("visitor-management/api/v1/get-corporate-property-employee", {
      corporate_id: formData?.corporate_id,
      property_id: propertyId,
    })
    .then((res) => {
      if (res?.data?.status) {
        // console.log("getCorporatePropertyEmployee", res?.data?.data);
        setUsersList(res?.data?.data);
      } else {
        setUsersList([]);
      }
    });
  };

  console.log(createdBy,"ccfghjkl")
  // useEffect(() => {
  //   console.log("responseEndTime", responseEndTime);
  //   console.log("resolutionEndTime", resolutionEndTime);
  // }, [responseEndTime, resolutionEndTime]);

  const calculateEndTimeResponse = (
    StartDateAndTime,
    isHours,
    responseTime,
    mgStart,
    mgEnd,
    isWorkingHours = 0
  ) => {
    const DateIssueCreated = StartDateAndTime;
    const TSIssueCreated = new Date(DateIssueCreated).getTime();
    const [mgStartHours, mgStartMinutes, mgStartSeconds] = mgStart.split(":");
    const [mgEndHours, mgEndMinutes, mgEndSeconds] = mgEnd.split(":");

    const TSMgTmrwStart = new Date(
      2023,
      0,
      2,
      +mgStartHours,
      +mgStartMinutes,
      +mgStartSeconds
    );
    const TSMgStart = new Date(
      2023,
      0,
      1,
      +mgStartHours,
      +mgStartMinutes,
      +mgStartSeconds
    );
    const TSMgEnd = new Date(
      2023,
      0,
      1,
      +mgEndHours,
      +mgEndMinutes,
      +mgEndSeconds
    );

    const cutOutTime = Math.abs(TSMgEnd - TSMgTmrwStart);
    const workingTime = Math.abs(TSMgEnd - TSMgStart);

    const DateIssueWorkEnd = new Date(
      DateIssueCreated.getFullYear(),
      DateIssueCreated.getMonth(),
      DateIssueCreated.getDate(),
      +mgEndHours,
      +mgEndMinutes,
      +mgEndSeconds
    );
    const TSIssueWorkEnd = DateIssueWorkEnd.getTime();

    if (isHours === 1) {
      // console.log("Response In Hours");
      const [splittedResponseHours, splittedResponseMinutes] =
        responseTime.split(":");
      const TSIssueEnd =
        TSIssueCreated +
        (+splittedResponseHours + +splittedResponseMinutes / 60) *
          60 *
          60 *
          1000;

      if (TSIssueWorkEnd > TSIssueEnd) {
        const tempDate = new Date(TSIssueEnd);
        const temp =
          tempDate.getDay() === 0
            ? new Date(tempDate.setDate(tempDate.getDate() + 1))
            : tempDate;
        setResponseEndTime(temp);

        return temp;
      } else if (TSIssueWorkEnd < TSIssueEnd) {
        const tempDate = new Date(
          TSIssueWorkEnd + cutOutTime + (TSIssueEnd - TSIssueWorkEnd)
        );
        const temp =
          tempDate.getDay() === 0
            ? tempDate.setDate(tempDate.getDate() + 1)
            : tempDate;
        // console.log("temp", temp);
        setResponseEndTime(temp);

        return temp;
      }
    } else if (isHours === 0) {
      // console.log("Response in days");
      const TSIssueEnd = TSIssueCreated + workingTime;
      const DateIssueEnd = new Date(TSIssueEnd);

      if (TSIssueWorkEnd > TSIssueEnd) {
        const tempDate = new Date(TSIssueEnd);
        const temp =
          tempDate.getDay() === 0
            ? new Date(tempDate.setDate(tempDate.getDate() + 1))
            : tempDate;
        setResponseEndTime(temp);

        return temp;
      } else if (TSIssueWorkEnd < TSIssueEnd) {
        const tempDate = new Date(
          TSIssueWorkEnd + cutOutTime + (TSIssueEnd - TSIssueWorkEnd)
        );
        const temp =
          tempDate.getDay() === 0
            ? new Date(tempDate.setDate(tempDate.getDate() + 1))
            : tempDate;
        setResponseEndTime(temp);
        return temp;
      }
    }
  };

  const calculateEndTimeResolution = (
    StartDateAndTime,
    isHours,
    resolutionTime,
    mgStart,
    mgEnd,
    isWorkingHours = 0
  ) => {
    const DateIssueCreated = new Date(StartDateAndTime);
    const TSIssueCreated = new Date(DateIssueCreated).getTime();
    const [mgStartHours, mgStartMinutes, mgStartSeconds] = mgStart.split(":");
    const [mgEndHours, mgEndMinutes, mgEndSeconds] = mgEnd.split(":");

    const TSMgTmrwStart = new Date(
      2023,
      0,
      2,
      +mgStartHours,
      +mgStartMinutes,
      +mgStartSeconds
    );
    const TSMgStart = new Date(
      2023,
      0,
      1,
      +mgStartHours,
      +mgStartMinutes,
      +mgStartSeconds
    );
    const TSMgEnd = new Date(
      2023,
      0,
      1,
      +mgEndHours,
      +mgEndMinutes,
      +mgEndSeconds
    );
    const cutOutTime = Math.abs(TSMgEnd - TSMgTmrwStart);
    const workingTime = Math.abs(TSMgEnd - TSMgStart);

    const DateIssueWorkEnd = new Date(
      DateIssueCreated.getFullYear(),
      DateIssueCreated.getMonth(),
      DateIssueCreated.getDate(),
      +mgEndHours,
      +mgEndMinutes,
      +mgEndSeconds
    );
    const TSIssueWorkEnd = DateIssueWorkEnd.getTime();

    if (isHours === 1) {
      // console.log("laksh", "hours");
      // resolutionTime = "9:00"; //RESETS THE VALUE WHEN IT IS ABOVE 9 HRS
      //if it is working hours then working time hours should be placed here
      const [splittedResponseHours, splittedResponseMinutes] =
        resolutionTime.split(":");
      const TSIssueEnd =
        TSIssueCreated +
        (+splittedResponseHours + +splittedResponseMinutes / 60) *
          60 *
          60 *
          1000;
      const DateIssueEnd = new Date(TSIssueEnd);
      if (TSIssueWorkEnd > TSIssueEnd) {
        const tempDate = new Date(TSIssueEnd);
        const temp =
          tempDate.getDay() === 0
            ? new Date(tempDate.setDate(tempDate.getDate() + 1))
            : tempDate;
        // console.log("sri", tempDate.getDay());
        setResolutionEndTime(temp);
        return temp;
        // return new Date(TSIssueEnd)
      } else if (TSIssueWorkEnd < TSIssueEnd) {
        const tempDate = new Date(
          TSIssueWorkEnd + cutOutTime + (TSIssueEnd - TSIssueWorkEnd)
        );
        const temp =
          tempDate.getDay() === 0
            ? new Date(tempDate.setDate(tempDate.getDate() + 1))
            : tempDate;
        setResolutionEndTime(temp);
        return temp;
      }
    } else if (isHours === 0) {
      // console.log("laksh", "days", resolutionTime);
      const hours_they_need = workingTime * +resolutionTime;
      let TSIssueEnd = TSIssueCreated + workingTime;
      let DateIssueEnd = new Date(TSIssueEnd);

      if (TSIssueWorkEnd > TSIssueEnd) {
        const tempDate = new Date(TSIssueEnd);
        const temp =
          tempDate.getDay() === 0
            ? new Date(tempDate.setDate(tempDate.getDate() + 1))
            : tempDate;
        setResolutionEndTime(temp);

        return temp;
      } else if (TSIssueWorkEnd < TSIssueEnd) {
        for (let i = 1; i <= resolutionTime; i++) {
          let DateUpIssueCreated = new Date(
            TSIssueWorkEnd + cutOutTime + (TSIssueEnd - TSIssueWorkEnd)
          );
          let TSUpIssueCreated = DateUpIssueCreated.getTime();
          TSIssueEnd = TSUpIssueCreated + workingTime;
          DateIssueEnd = new Date(TSIssueEnd);
        }

        const tempDate = new Date(
          TSIssueWorkEnd + cutOutTime + (TSIssueEnd - TSIssueWorkEnd)
        );
        const temp =
          tempDate.getDay() === 0
            ? new Date(tempDate.setDate(tempDate.getDate() + 1))
            : tempDate;
        setResolutionEndTime(temp);
        return temp;
      }
    }
  };

  const filterCategory = (catId) => {
    const category = issueCategoryList?.find((each) => each.id == catId);

    const {
      created_at,
      is_response_hour,
      response_period,
      is_resolution_hour,
      resolution_period,
      mg_start_time,
      mg_end_time,
    } = category;
    calculateEndTimeResponse(
      new Date(),
      is_response_hour,
      response_period,
      mg_start_time,
      mg_end_time
    );
    calculateEndTimeResolution(
      new Date(),
      is_resolution_hour,
      resolution_period,
      mg_start_time,
      mg_end_time
    );
  };

  console.log(issueCategoryList,"nnmmmkkkkllll")

  const getIssueSubCategoryHAndler=(d1)=>{
    console.log(d1,"fgfgfgfg");
    getMethod(`issue-management/api/v1/issue-category/${d1}`)
    .then((res)=>{
      console.log(res.data.data?.issue_sub_category,"mkioop");
      setIssueSubCategoryList(res?.data.data.issue_sub_category)
    
      
    })
  }

  // console.log(issueSubCategoryList,"fffggghhhh")

  const [EmplyeeOnboardopen, setEmplyeeOnboardOpen] = useState(false);

  const handleEmployeeOnboardingHandler = () => {
    setEmplyeeOnboardOpen(true);
  };

  const handleUserOnboardingClose = () => {
    setEmplyeeOnboardOpen(false);
  };

  const handleUserOnboardingSubmit = (e) => {
    e.preventDefault();
    
    let data ={
      corporate_id:formData.corporate_id,
      role_id:7,
      employee_name:employeeName,
      mobile_no:employeeNumber,
      email:employeeEmail,
      user_type:"Employee",
      status:1,
      password:employeeNumber,
      contact_number:employeeNumber,
      created_by:createdBy

    }

    postMethod("user-management/user-employee/", data)

    // API.postMethodWithoutToken("user-management/user-employee/",data)
    // .then((response) => response.json())
    .then((res)=>{console.log(res)
      Swal.fire({
        html: `Employee Onboarded Succesfully`,
        icon: "success",
        // title: "Success",
        showConfirmButton: false,
        timer: 2000,
      });
      const newUser = {
        id: res.data.result.id,
        name: res.data.result.name,
      };
      
      // Update usersList with the new user
      setUsersList((prevList) => [...prevList, newUser]);

      // console.log(res?.data.result?.id,"mmmkkkllll")
      // console.log(res?.data.result?.name,"name")
      setEmployeeName("")
      setEmployeeEmail("")
      setEmployeeNumber("")
      handleUserOnboardingClose()
    })
    .catch((err)=>console.log(err))
    console.log(data,"ccfghj")
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    // console.log(name, value);
    if (name === "issue_category_id") {
      issueCategoryList.forEach((issue) => {
        if (+value === issue.id) {
          // formData.responseIsHour = issue.is_response_hour == 1 ? 1 : 0;
          // formData.resolutionIsHour = issue.is_resolution_hour == 1 ? 1 : 0;
          // // formData.issueCreated = new Date().toISOString();
          // formData.responseTimePeriod = issue.response_period;
          // formData.resolutionTimePeriod = issue.resolution_period;
          // formData.mgStart = issue.mg_start_time;
          // formData.mgEnd = issue.mg_end_time;
          getIssueSubCategoryHAndler(value)
          // console.log("sri", formData.responseIsHour, formData.resolutionIsHour, formData.responseTimePeriod, formData.resolutionTimePeriod, formData.mgStart, formData.mgEnd);
        }
      });
    }
    if(name==="issue_sub_category_id"){
      issueSubCategoryList.forEach((issue) => {
        if (+value === issue.id) {
      formData.responseIsHour = issue.is_response_hour == 1 ? 1 : 0;
      formData.resolutionIsHour = issue.is_resolution_hour == 1 ? 1 : 0;
      // formData.issueCreated = new Date().toISOString();
      formData.responseTimePeriod = issue.response_period;
      formData.resolutionTimePeriod = issue.resolution_period;
      formData.mgStart = issue.mg_start_time;
      formData.mgEnd = issue.mg_end_time;
        }
    })
  }
    setFormData({ ...formData, [name]: value });
    if (name === "role_id" && value == 10) {
      getUsersList(value);
    }
    if (name === "role_id" && value == 5) {
      getUsersList(value);
    }
    if (name === "role_id" && value == 7) {
      getCorporateList();
    }
    if (name === "role_id" && value == 6) {
      getCorporateList();
    }
    if (name === "role_id" && value == 8) {
      getUsersList(value);
    }
    if (
      name === "user_id" &&
      formData?.role_id != 7 &&
      formData?.role_id != 10
    ) {
      getPropertyList(value);
    }
    if (name === "user_id" && formData?.role_id == 10) {
      return getAllProperties();
    }
    if (name === "corporate_id") {
      getCorporatePropertyList(value);
      // setUsersList([]);
    }

    if (formData?.role_id == 7 && name === "property_id") {
      getCorporatePropertyEmployee(value);
    }
  };

  useEffect(()=>{
    const storedAcessRightsData = JSON.parse(localStorage.getItem('acsRightUser'));
    const TOgetUsersRoles = JSON.parse(localStorage.getItem('user'));
    // console.log(storedAcessRightsData.property_details.map((k)=>k.id),"jdfgjhsdgjhsdf")
    // console.log(TOgetUsersRoles.roles.map((c)=>c.role_name),"togetroles");
    if (storedAcessRightsData && storedAcessRightsData.property_details) {
  const userPropertyIds = storedAcessRightsData?.property_details.map((k) => k.id);
  console.log(userPropertyIds,"lgtyuio");
  setLoggedPersonProperty(userPropertyIds);
}
  },[])

  function capitalizeWords(string) {
    return string.replace(/\b\w/g, char => char.toUpperCase());
}
  

  // useEffect(() => console.log("issueImages", file), [file]);
  // console.log(formData, "data");
  const handleSubmit = (e) => {
    e.preventDefault();
    // return console.log(formData);
    //calculating  response end time
    const responseEndTime = calculateEndTime(
      new Date().toISOString(), //2023, 1, 3, 19, 30, 0
      formData.responseIsHour,
      formData.responseTimePeriod,
      formData.mgStart,
      formData.mgEnd
    );
    const resolutionEndTime = calculateEndTime(
      new Date().toISOString(), //2023, 1, 3, 19, 30, 0
      formData.resolutionIsHour,
      formData.resolutionTimePeriod,
      // 1,
      formData.mgStart,
      formData.mgEnd
    );
    let formDataToSend = new FormData();

    issueImages?.forEach((value, index) => {
      // console.log("Upload Image", value, index);
      formDataToSend.append(`issue_image[${index}]`, value);
    });

    if (!issueImages) {
      formDataToSend.append("issue_image", []);
    }
    //adding rresponse &resolution end time
    //gain calculate date accoding to mysql timezone
    formDataToSend.append("response_end_time", responseEndTime.toISOString()); //new Date().toISOString().slice(0, 19).replace('T', ' ')
    formDataToSend.append(
      "resolution_end_time",
      resolutionEndTime.toISOString()
    );
    // formDataToSend.append("issue_title", formData?.issue_title);
    // formDataToSend.append("description", formData?.description);

formDataToSend.append("issue_title", capitalizeWords(formData?.issue_title));
formDataToSend.append("description", capitalizeWords(formData?.description));

    formDataToSend.append(
      "user_id",
      formData?.role_id == 6 ? null : formData?.user_id
    );
    formDataToSend.append(
      "issue_category_id",
      Number(formData?.issue_category_id)
    );

    formDataToSend.append(
      "issue_sub_category_id",
      Number(formData?.issue_sub_category_id)
    );
    formDataToSend.append("property_id", formData?.property_id);
    formDataToSend.append("role_id", formData?.role_id);
    // formDataToSend.append(
    //   "response_end_time",
    //   new Date(responseEndTime).toISOString()
    // );
    // formDataToSend.append(
    //   "resolution_end_time",
    //   new Date(resolutionEndTime).toISOString()
    // );
    formDataToSend.append(
      "corporate_id",
      formData?.role_id == 5 ? null : formData?.corporate_id
    );
    formData?.resolved_by !== null ??
      formDataToSend.append("resolved_by", formData?.resolved_by);
    formData?.resolved_at !== null ??
      formDataToSend.append("resolved_at", formData?.resolved_at);

    formDataToSend.append("status", Number(formData?.status));
    formDataToSend.append("created_by", Number(createdBy));

    // console.log("Formdata-->", ...formDataToSend);

    // for (var pair of formDataToSend.entries()) {
    //   console.log(pair[0] + ", " + pair[1]);
    // }formDataToSend

    console.log(formDataToSend,"formDataToSend");

    fileupload("issue-management/api/v1/issue", formDataToSend)
      .then((res) => {
        if (res?.data?.status) {
          setFormData({
            issue_title: "",
            description: "",
            issue_category_id: "",
            issue_sub_category_id: "",
            role_id: "",
            property_id: "",
            user_id: "",
            corporate_id: "",
            resolved_by: "",
            resolved_at: "",
            status: 1,
          });

          dispatch(closeModal());
        }
      })
      .catch((err) => console.log(err));
  };

  return (
    <div>
    <form onSubmit={handleSubmit} className="create-ticket-form">
      <Row>
        <div className="form-group col-md-6 col-sm-12">
          <label className="form-label" htmlFor="issue_category_id">
            Issue Category <span className="text-danger">*</span>
          </label>
          <select
            id="issue_category_id"
            name="issue_category_id"
            value={formData.issue_category_id}
            onChange={handleChange}
            required
          >
            <option value="">Select Issue Category</option>
            {issueCategoryList?.map((issue_cat, index) => (
              // console.log("lak", issue_cat),
              <option key={index} value={issue_cat?.id}>
                {issue_cat?.issue_category_name}
              </option>
            ))}
          </select>
        </div>

        <div className="form-group col-md-6 col-sm-12">
          <label className="form-label" htmlFor="issue_sub_category_id">
            Issue Sub Category <span className="text-danger">*</span>
          </label>
          <select
            id="issue_sub_category_id"
            name="issue_sub_category_id"
            value={formData.issue_sub_category_id}
            onChange={handleChange}
            required
          >
             <option value="">Select Issue Sub Category</option>
  {issueSubCategoryList && issueSubCategoryList.length > 0 ? (
    issueSubCategoryList.map((issue_sub_cat, index) => (
      <option key={index} value={issue_sub_cat?.id}>
        {issue_sub_cat?.issue_sub_category_name}
      </option>
    ))
  ) : (
    <option value="" disabled>No Sub Category</option>
  )}
          </select>
        </div>

        <div className="form-group col-md-6 col-sm-12">
          <label className="form-label" htmlFor="role_id">
            Select User Type <span className="text-danger">*</span>
          </label>
          <select
            id="role_id"
            name="role_id"
            ref={typeOfUser}
            value={formData.role_id}
            onChange={handleChange}
            required
          >
            <option value="">Select User Type</option>
            <option value={10}>MG-Employee</option>
            <option value={5}> Member (BRH)</option>
            {/* <option value={6}>Corporate </option> */}
            <option value={7}>Corporate / Employee </option>
            {/* <option value={8}>Sales Executive</option> */}
          </select>
        </div>
        {formData?.role_id == 7 && (
          <div className="form-group col-md-6 col-sm-12">
            <label className="form-label" htmlFor="corporate_id">
              Select Corporate <span className="text-danger">*</span>
            </label>
            <select
              id="corporate_id"
              name="corporate_id"
              ref={typeOfUser}
              value={formData.corporate_id}
              onChange={handleChange}
              required
            >
              <option value="">Select Corporate</option>
              {corporateUsersList?.map((user, index) => (
                <option key={index} value={user?.id}>
                  {user?.name}
                </option>
              ))}
            </select>
          </div>
        )}

        {formData?.role_id == 7 && (
          <>
            <div className="form-group col-md-6 col-sm-12">
              <label className="form-label" htmlFor="property_id">
                Select Corporate Property <span className="text-danger">*</span>
              </label>
              <select
                id="property_id"
                name="property_id"
                value={formData.property_id}
                onChange={handleChange}
                required
                className={`${
                  networkErrors?.propertyListEmptyErr ?? "text-danger"
                }`}
              >
                <option
                  className={`${
                    networkErrors?.propertyListEmptyErr ?? "text-danger"
                  }`}
                  value=""
                >
                  {networkErrors?.propertyListEmptyErr ?? "Select Property"}
                </option>
                {networkErrors?.propertyListEmptyErr !==
                  "Properties not found" &&
                  filteredStaffPropertyList?.map((property, index) => (
                    <option key={index} value={property?.id}>
                      {property?.property_name}
                    </option>
                  ))}
              </select>
            </div>
            <div className="form-group col-md-6 col-sm-12">

<div className="row" style={{ display:'flex',flexDirection:'row', alignItems:'self-end' }}>
<div className="form-group col-md-10 col-sm-10">
   
   <label className="form-label" htmlFor="user_id">
                (Corporate Emp)Issue Raised for{" "}
                <span className="text-danger">*</span>
              </label>
              <select
                id="user_id"
                name={"user_id"}
                value={formData?.user_id}
                onChange={handleChange}
                required
                disabled={
                  formData.corporate_id === null ||
                  formData.property_id === null
                    ? true
                    : false
                }
              >
                <option value="">Select User</option>
                {usersList?.map((user, index) => (
                  <option key={index} value={user?.id}>
                    {user?.name}
                  </option>
                ))}
              </select>
           
              </div>
            <div className="form-group col-md-2 col-sm-2">
                <AddCircleOutline
                 onClick={handleEmployeeOnboardingHandler}
                style={{ fontSize: '35px' ,cursor:'pointer' ,color:"rgb(250, 166, 25)"}}  />
              </div>
          </div>
          </div>
          </>
        )}
        
        {formData?.role_id != 7 && (
          <>
          <div className="form-group col-md-6 col-sm-12">
       <label className="form-label" htmlFor="user_id">
                Issue Raised for <span className="text-danger">*</span>
              </label>
              <select
                id="user_id"
                name={formData?.role_id == "6" ? "corporate_id" : "user_id"}
                value={
                  formData?.role_id == "6"
                    ? formData?.corporate_id
                    : formData?.user_id
                }
                onChange={handleChange}
                required
              >
                <option value="">Select User</option>
                {usersList?.map((user, index) => (
                  <option key={index} value={user?.id}>
                    {user?.name}
                  </option>
                ))}
              </select>
              
            </div>
           



            <div className="form-group col-md-6 col-sm-12">
              <label className="form-label" htmlFor="property_id">
                Property Name <span className="text-danger">*</span>
              </label>
              <select
                id="property_id"
                name="property_id"
                value={formData.property_id}
                onChange={handleChange}
                required
                className={`${
                  networkErrors?.propertyListEmptyErr ?? "text-danger"
                }`}
              >
                <option
                  className={`${
                    networkErrors?.propertyListEmptyErr ?? "text-danger"
                  }`}
                  value=""
                >
                  {networkErrors?.propertyListEmptyErr ?? "Select Property"}
                </option>
                {networkErrors?.propertyListEmptyErr !==
                  "Properties not linked" &&
                  filteredStaffPropertyList?.map((property, index) => (
                    <option key={index} value={property?.id}>
                      {property?.property_name}
                    </option>
                  ))}
              </select>
            </div>
          </>

        )}

        <div className="form-group col-md-6 col-sm-12">
          <label className="form-label" htmlFor="issue_title">
            Issue Title <span className="text-danger">*</span>
          </label>
          <input
            id="issue_title"
            name="issue_title"
            value={formData.issue_title}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group col-md-6 col-sm-12">
          <label className="form-label" htmlFor="description">
            Issue Description
          </label>
          <textarea
            rows="4"
            id="description"
            name="description"
            value={formData.description}
            onChange={handleChange}
            required
          />
        </div>

        <div className="form-group col-md-6 col-sm-12 d-flex flex-column   mr-auto ">
          <label className="form-label" htmlFor="upload_images">
            Issue Images
          </label>
          <input
            className="form-control d-none "
            onChange={(e) => handleFilesChange(e, uploadRef)}
            name="issue_image"
            id="issue_image"
            accept="image/*"
            type="file"
            multiple
            ref={uploadRef}
          />
          <label
            className="form-label btn btn-sm  text-dark rounded-0 ml-5"
            style={{ backgroundColor: "#faa619" }}
            htmlFor="issue_image"
          >
            Choose Images
          </label>

          <div className="d-flex flex-row overflow-auto">
            {fileError ? (
              <span className="text-danger text-small ml-1">{fileError}</span>
            ) : (
              file &&
              file.map((url, index) => {
                return (
                  <div
                    key={index}
                    className="mr-2 mb-2 mt-0 card card-top shadow-lg p-0 mb-1 bg-white rounded-0"
                  >
                    <button
                      type="button"
                      onClick={() => handleImageUrlDelete(index)}
                      className="d-inline btn btn-sm p-0 m-0 bg-light ml-auto rounded-0"
                    >
                      &times;
                    </button>
                    <img
                      alt="preview-img"
                      variant="top"
                      src={url}
                      className="card-img-top "
                    />
                    <small className="text-small d-inline  p-0 m-0 bg-light ml-auto mr-auto rounded-0">
                      {filesName ? filesName[index] : "issue-img"}
                    </small>
                    {/* {issueImages ? issueImages : ""} */}
                  </div>
                );
              })
            )}
          </div>
        </div>

        <div className="form-group col-md-6 col-sm-12">
          <label className="form-label" htmlFor="status">
            Status <span className="text-danger">*</span>
          </label>
          <select
            id="status"
            name="status"
            value={formData.status}
            onChange={handleChange}
            required
          >
            {issueStatusMaster?.map((status, index) => (
              <option
                disabled={status?.id !== 1}
                key={index}
                value={status?.id}
              >
                {status?.label}
              </option>
            ))}
          </select>
        </div>
      </Row>
      <div className="col-md-12 modal-footer">
        <Button
          style={{ width: "250px" }}
          variant="secondary"
          className=" rounded-0 border-0"
          type="button"
          onClick={() => dispatch(closeModal())}
        >
          Close
        </Button>
        <Button
          style={{ background: "#faa619", width: "250px" }}
          type="submit"
          className=" rounded-0 border-0"
        >
          Create Ticket
        </Button>
        {/* <Snackbar open={snackbarOpen}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          autoHideDuration={6000} >
          <Alert onClose={setSnackbarOpen(false)} severity="success" sx={{ width: '100%' }}>
            {networkErrors.propertyListEmptyErr}
          </Alert>
        </Snackbar> */}
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          open={snackbarOpen}
          // onClose={handleClose}
          // message={networkErrors.propertyListEmptyErr}
          key={new Date().toDateString()}
          severity="warning"
        >
          <Alert severity="warning" sx={{ width: "100%" }}>
            {networkErrors.propertyListEmptyErr}
          </Alert>
        </Snackbar>
      </div>
    </form>

    <Modal
      // maxWidth="lg"
      // fullWidth
      show={EmplyeeOnboardopen}
      onClose={handleUserOnboardingClose}
    >
      <Modal.Title style={{ paddingLeft:'25px',paddingTop:'15px' }} >Onboarding Employee</Modal.Title>
      <Modal.Body>
        <form 
        onSubmit={handleUserOnboardingSubmit}
        className="create-ticket-form" style={{border:'none' }} >
          <div className="row">
            <div className="form-group col-md-6 col-sm-12">
              <label className="form-label" htmlFor="employeeName">
                Employee Name<span className="text-danger">*</span>
              </label>
              <input
                id="employeeName"
                value={employeeName} 
                onChange={(e) => setEmployeeName(e.target.value)}
                required
              />
            </div>

            <div className="form-group col-md-6 col-sm-12">
              <label className="form-label" htmlFor="employeeEmail">
                Employee Email<span className="text-danger">*</span>
              </label>
              <input
                type="email"
                id="employeeEmail"
                value={employeeEmail}
                onChange={(e) => setEmployeeEmail(e.target.value)}
                required
              />
            </div>

            <div className="form-group col-md-6 col-sm-12">
              <label className="form-label" htmlFor="employeeCode">
                Employee Contact Number<span className="text-danger">*</span>
              </label>
              <input
                type="number"
                id="employeeNumber"
                value={employeeNumber}
                onChange={(e) => {
                  const value = e.target.value;
              
                  // Only set the value if it's a valid 10-digit number
                  if (/^\d{0,10}$/.test(value)) {
                    setEmployeeNumber(value);
                  }
                }}
                maxLength={10} 
                required
              />
            </div>
          </div>

          <input type="submit"  />
        </form>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={handleUserOnboardingClose} color="primary">
          Cancel
        </Button>
        {/* <Button onClick={handleUserOnboardingSubmit} color="primary">
          Submit
        </Button> */}
      </Modal.Footer>
    </Modal>
    </div>
  );
}

export default CreateTicket;
