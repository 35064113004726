import { createSlice } from "@reduxjs/toolkit";
const user = JSON.parse(localStorage.getItem("user"));
const initialState = {
  isModalClicked: false,
  isEditModalClicked: false,
  notificationData: [],
  issuesData: [],
  issuesMasterData: [],
  // filteredValue: user?.roles[0]?.role_name === "Sales Executive" ? 7 : 1,
  filteredValue: (user && user.roles && user.roles.length > 0 && user.roles[0]?.role_name === "Sales Executive") ? 7 : 1,

  loading: false,
  // fullScreenToggle: false,
  fullScreenToggle: false,
};

export const modalSlice = createSlice({
  name: "modalButton",
  initialState,
  reducers: {
    openModal: (state, action) => {
      state.isModalClicked = true;
    },
    closeModal: (state, action) => {
      state.isModalClicked = false;
    },
    openEditModal: (state, action) => {
      state.isEditModalClicked = true;
    },
    closeEditModal: (state, action) => {
      state.isEditModalClicked = false;
    },
    notificationsData: (state, action) => {
      state.notificationData = action.payload;
    },
    issuesListData: (state, action) => {
      state.issuesData = action.payload;
    },
    issuesMasterData: (state, action) => {
      state.issuesMasterData = action.payload;
    },
    counterBoxValue: (state, action) => {
      state.filteredValue = action.payload;
    },
    screenLoading: (state, action) => {
      state.loading = action.payload;
    },
    fullScreenToggler: (state, action) => {
      state.fullScreenToggle = action.payload;
    },
  },
});
export const {
  openModal,
  closeModal,
  openEditModal,
  closeEditModal,
  notificationsData,
  issuesListData,
  issuesMasterData,
  counterBoxValue,
  fullScreenToggler,
  screenLoading,
} = modalSlice.actions;
export default modalSlice.reducer;
