import React, { useEffect, useState } from "react";
import Charts from "./Charts";
import TableComponent, {
  calculateActualTimeDifference,
  calculateActualTimeDifferenceTwo,
} from "../TablesSections/TableComponent";
import "./sections.scss";
import { Col, Container, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import { getMethod, getMethodAsync } from "../../API_SERVICES/ApiServices";
import {
  issuesListData,
  issuesMasterData,
  notificationsData,
  screenLoading,
} from "./ModalSlice";
import { Skeleton } from "@mui/material";
import moment, { now } from "moment";
import API from ".././Dashboards/API_Services/ServiceLayer";
import { Link } from "react-router-dom";
import { AiOutlineConsoleSql } from "react-icons/ai";

const Sections = () => {
  const [DateEvents, setDateEvents] = useState([]);
  const [DeptHeadPropertiesForHeader, setDeptHeadPropertiesForHeader] = useState([]);
  const [DeptHeadProperties, setDeptHeadProperties] = useState([]);
  const [userDeptHeadPropertyID, setUserDeptHeadPropertyID] = useState([]);
  const [UserDeptHeadPropertyIDHeader, setUserDeptHeadPropertyIDHeader] = useState([]);
  
  const [responseEndTime, setResponseEndTime] = useState();
  const [resolutionEndTime, setResolutionEndTime] = useState();
  const [formData, setFormData] = useState({
    issue_title: "",
    description: "",
    issue_category_id: "",
    role_id: "",
    property_id: "",
    user_id: "",
    corporate_id: "",
    resolved_by: "",
    resolved_at: "",
    status: "",
  });
  const [show, setShow] = useState(false);
  const [Accesible, setAccesible] = useState(false);
  const [matchrProp, setmatchrProp] = useState([]);


  // const [nulldepartment, setnulldepartment] = useState(false);
  // const [hasDeptID, sethasDeptID] = useState(false);
  // const [limitationDept, setlimitationDept] = useState(false);

  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10, //customize the default page size
  });
  const dispatch = useDispatch();

  const [activeTab, setActiveTab] = useState(0);

  const tabs = ["Events", "Charts"];

  const modalClicked = useSelector((state) => state?.modal?.isModalClicked);
  const editModalClicked = useSelector(
    (state) => state?.modal?.isEditModalClicked
  );
  const boxValue = useSelector((state) => state?.modal?.filteredValue);
  const filteredIssues = useSelector((state) => state?.modal?.issuesData);

  const loading = useSelector((state) => state?.modal?.loading);
  const [isLoading, setIsLoading] = useState(false);
  const today = new Date().toISOString().split("T")[0];
  const [evntschooseDate, setevntschooseDate] = useState(today);
  const [AcsRightUser, setAcsRightUser] = useState({});

  //////////////////////////////
  const calculateDelayResponses = (end, responsed, mgStart, mgEnd, index) => {
    //checking
    const copiedResponsed = new Date(JSON.parse(JSON.stringify(responsed)));
    const copiedEnd = new Date(JSON.parse(JSON.stringify(end)));
    let endDate = new Date(end);
    let responsedDate = new Date(responsed);

    const [mgStartHours, mgStartMinutes, mgStartSeconds] = mgStart.split(":");
    const [mgEndHours, mgEndMinutes, mgEndSeconds] = mgEnd.split(":");
    let isDelay;
    let delay = 0;

    const TSMgTmrwStart = new Date(
      2023,
      0,
      2,
      +mgStartHours,
      +mgStartMinutes,
      +mgStartSeconds
    );
    const TSMgStart = new Date(
      2023,
      0,
      1,
      +mgStartHours,
      +mgStartMinutes,
      +mgStartSeconds
    );
    const TSMgEnd = new Date(
      2023,
      0,
      1,
      +mgEndHours,
      +mgEndMinutes,
      +mgEndSeconds
    );
    const cutOutTime = Math.abs(TSMgEnd - TSMgTmrwStart);
    const workingTime = Math.abs(TSMgEnd - TSMgStart);

    let endDateTs = endDate.getTime();
    let responsedTs = responsedDate.getTime();
    let dates = [];

    if (endDateTs < responsedTs) {
      //1.add dates
      let tempEndDate = new Date(endDate);
      let tempEndTs = tempEndDate.getTime();
      isDelay = true;
      dates.push(endDate);
      while (tempEndTs < responsedTs) {
        // ----------eliminate sundays
        if (responsedDate.getDay() === 0) {
          //trying to set previous day's end date
          responsedDate = new Date(
            responsedDate.getFullYear(),
            responsedDate.getMonth(),
            responsedDate.getDate() - 1,
            +mgEndHours,
            +mgEndMinutes,
            +mgEndSeconds
          );
        }
        // ----------logic for same dates
        if (
          endDate.getDate() === responsedDate.getDate() &&
          endDate.getDay() === responsedDate.getDay()
        ) {
          // ---------- above check working hours then set working end hours
          if (responsedDate.getHours() > 18) {
            const fixedDate = new Date(
              responsedDate.getFullYear(),
              responsedDate.getMonth(),
              responsedDate.getDate(),
              +mgEndHours,
              +mgEndMinutes,
              +mgEndSeconds
            );
            dates.push(fixedDate);
          } else {
            dates.push(responsedDate);
          }
          break;
        }
        // -------------adding dates to dates list
        let addDate = new Date(
          tempEndDate.getFullYear(),
          tempEndDate.getMonth(),
          tempEndDate.getDate() + 1,
          +mgEndHours,
          +mgEndMinutes,
          +mgEndSeconds
        );
        // ----------eliminate sundays
        if (addDate.getDay() === 0) {
          addDate.setDate(addDate.getDate() + 1);
        }
        if (addDate.getDate() === responsedDate.getDate()) {
          let finalDate;
          if (responsedDate.getHours() > 18) {
            finalDate = new Date(
              addDate.getFullYear(),
              addDate.getMonth(),
              addDate.getDate(),
              +mgEndHours,
              +mgEndMinutes,
              +mgEndSeconds
            );
            dates.push(finalDate);
          } else if (responsedDate.getHours() < 10) {
            //logic for fixing before working start hours
            finalDate = new Date(
              addDate.getFullYear(),
              addDate.getMonth(),
              addDate.getDate() - 1,
              +mgEndHours,
              +mgEndMinutes,
              +mgEndSeconds
            );
            //eleiminate sunday
            if (finalDate.getDay() != 0) {
              //try to eliminate fake dates
              if (
                !(
                  dates.length > 1 &&
                  dates[dates.length - 1].getDate() === finalDate.getDate()
                )
              ) {
                dates.push(finalDate);
              }
            }
          } else if (responsedDate.getHours() === 10) {
            //logic for working hours
            if (responsedDate.getMinutes() === 0) {
              //eliminate befoe 10.01 AM
              finalDate = new Date(
                addDate.getFullYear(),
                addDate.getMonth(),
                addDate.getDate() - 1,
                +mgEndHours,
                +mgEndMinutes,
                +mgEndSeconds
              );
              //eliminate sunday
              if (finalDate.getDay() === 0) {
                finalDate = new Date(
                  finalDate.setDate(finalDate.getDate() - 1)
                );
              }
              //eliminate fake dates
              if (
                !(
                  dates.length > 1 &&
                  dates[dates.length - 1].getDate() === finalDate.getDate()
                )
              ) {
                dates.push(finalDate);
              }
              //try to eliminate fake dates(backup)
              // if (
              //   dates.length > 2 &&
              //   dates[dates.length - 1].getDate() === finalDate.getDate()
              // ) {
              // } else {
              //   dates.push(finalDate);
              // }
            } else {
              //logic for working hours
              finalDate = new Date(
                addDate.getFullYear(),
                addDate.getMonth(),
                addDate.getDate(),
                responsedDate.getHours(),
                responsedDate.getMinutes(),
                responsedDate.getSeconds()
              );
              dates.push(finalDate);
            }
          } else {
            finalDate = new Date(
              addDate.getFullYear(),
              addDate.getMonth(),
              addDate.getDate(),
              responsedDate.getHours(),
              responsedDate.getMinutes(),
              responsedDate.getSeconds()
            );
            dates.push(finalDate);
          }
          break;
        } else {
          dates.push(addDate);
        }
        //logic for break while loop
        tempEndDate = addDate;
        tempEndTs = tempEndDate.getTime();
      }
    } else {
      let tempResponsed = new Date(responsed);
      let tempResponsedTs = tempResponsed.getTime();
      isDelay = false;

      while (endDateTs >= tempResponsedTs) {
        //reseting responsed date
        if (tempResponsed.getHours() < 10) {
          tempResponsed = new Date(
            tempResponsed.getFullYear(),
            tempResponsed.getMonth(),
            tempResponsed.getDate(),
            +mgStartHours,
            +mgStartMinutes,
            +mgStartSeconds
          );
        } else if (tempResponsed.getHours() > 18) {
          tempResponsed = new Date(
            tempResponsed.getFullYear(),
            tempResponsed.getMonth(),
            tempResponsed.getDate(),
            +mgEndHours,
            +mgEndMinutes,
            +mgEndSeconds
          );
        }
        //eliminate sunday
        if (tempResponsed.getDay() === 0) {
          tempResponsed = new Date(
            tempResponsed.getFullYear(),
            tempResponsed.getMonth(),
            tempResponsed.getDate() + 1,
            +mgStartHours,
            +mgStartMinutes,
            +mgStartSeconds
          );
        }
        //break loop
        if (
          tempResponsed.getDate() === endDate.getDate() &&
          tempResponsed.getDay() === endDate.getDay()
        ) {
          if (dates.length == 0) {
            dates.push(tempResponsed);
          }
          break;
        } else {
          dates.push(tempResponsed);
          tempResponsed = new Date(
            tempResponsed.getFullYear(),
            tempResponsed.getMonth(),
            tempResponsed.getDate() + 1,
            tempResponsed.getHours(),
            tempResponsed.getMinutes(),
            tempResponsed.getSeconds()
          );
          tempResponsedTs = tempResponsed.getTime();
        }
      }
      dates.push(endDate);
    }

    //calculate delay
    const firstDayStart = new Date(dates[0]);
    const firstDayEnd = new Date(
      firstDayStart.getFullYear(),
      firstDayStart.getMonth(),
      firstDayStart.getDate(),
      +mgEndHours,
      +mgEndMinutes,
      +mgEndSeconds
    );
    const secondDayEnd = new Date(dates[dates.length - 1]);
    const secondDayFirst = new Date(
      secondDayEnd.getFullYear(),
      secondDayEnd.getMonth(),
      secondDayEnd.getDate(),
      +mgStartHours,
      +mgStartMinutes + mgStartSeconds
    );
    const delayFirstDay = firstDayEnd.getTime() - firstDayStart.getTime();
    const delaySecondday = secondDayEnd.getTime() - secondDayFirst.getTime();
    delay = delaySecondday + delayFirstDay;
    if (dates.length > 2) {
      let addingdelay = dates.length - 2 ? (dates.length - 2) * 3.24e7 : 3.24e7;
      delay += addingdelay;
    } else if (firstDayStart.getDate() === secondDayFirst.getDate()) {
      delay =
        new Date(dates[dates.length - 1]).getTime() -
        new Date(dates[dates.length - 2]).getTime();
    }

    //calculate days,hours,minutes
    let diffDays = Math.floor(delay / workingTime); // days
    let diffHrs = Math.floor((delay % workingTime) / 3600000); // hours
    let diffMins = Math.round(((delay % workingTime) % 3600000) / 60000); // minutes
    //make delay string
    let delayString = `${diffDays}:${diffHrs}:${diffMins}`;
    return [delayString, isDelay];
  };

  const calculateSLA = (isHours, period) => {
    if (isHours === 1) {
      const [hours, minutes] = period.split(":");
      // console.log(hours, minutes);
      return { isHours: 1, hours, minutes };
    } else if (isHours === 0) {
      return { isHours: 0, period };
    }
  };
  const calculateEndTimeResponse = (
    StartDateAndTime,
    isHours,
    responseTime,
    mgStart,
    mgEnd,
    isWorkingHours = 0
  ) => {
    const DateIssueCreated = StartDateAndTime;
    const TSIssueCreated = new Date(DateIssueCreated).getTime();
    const [mgStartHours, mgStartMinutes, mgStartSeconds] = mgStart.split(":");
    const [mgEndHours, mgEndMinutes, mgEndSeconds] = mgEnd.split(":");
    // NOTE:
    // WE USE DEFAULT DATE MONTH YEAR BECOZ WE ARE CALCULATING TIMEGAP BTWN START AND END DATE
    const TSMgTmrwStart = new Date(
      2023,
      0,
      2,
      +mgStartHours,
      +mgStartMinutes,
      +mgStartSeconds
    );
    const TSMgStart = new Date(
      2023,
      0,
      1,
      +mgStartHours,
      +mgStartMinutes,
      +mgStartSeconds
    );
    const TSMgEnd = new Date(
      2023,
      0,
      1,
      +mgEndHours,
      +mgEndMinutes,
      +mgEndSeconds
    );

    const cutOutTime = Math.abs(TSMgEnd - TSMgTmrwStart);
    const workingTime = Math.abs(TSMgEnd - TSMgStart);

    const DateIssueWorkEnd = new Date(
      DateIssueCreated.getFullYear(),
      DateIssueCreated.getMonth(),
      DateIssueCreated.getDate(),
      +mgEndHours,
      +mgEndMinutes,
      +mgEndSeconds
    );
    const TSIssueWorkEnd = DateIssueWorkEnd.getTime();

    if (isHours === 1) {
      // console.log("Response In Hours");
      const [splittedResponseHours, splittedResponseMinutes] =
        responseTime.split(":");
      const TSIssueEnd =
        TSIssueCreated +
        (+splittedResponseHours + +splittedResponseMinutes / 60) *
          60 *
          60 *
          1000;

      if (TSIssueWorkEnd > TSIssueEnd) {
        const tempDate = new Date(TSIssueEnd);
        const temp =
          tempDate.getDay() === 0
            ? new Date(tempDate.setDate(tempDate.getDate() + 1))
            : tempDate;
        setResponseEndTime(temp);

        return temp;
      } else if (TSIssueWorkEnd < TSIssueEnd) {
        const tempDate = new Date(
          TSIssueWorkEnd + cutOutTime + (TSIssueEnd - TSIssueWorkEnd)
        );
        const temp =
          tempDate.getDay() === 0
            ? tempDate.setDate(tempDate.getDate() + 1)
            : tempDate;
        // console.log("temp", temp);
        setResponseEndTime(temp);

        return temp;
      } else {
        console.error("Unhandled timestring convertion!");
      }
    } else if (isHours === 0) {
      // console.log("Response in days");
      const TSIssueEnd = TSIssueCreated + workingTime;
      const DateIssueEnd = new Date(TSIssueEnd);

      if (TSIssueWorkEnd > TSIssueEnd) {
        const tempDate = new Date(TSIssueEnd);
        const temp =
          tempDate.getDay() === 0
            ? new Date(tempDate.setDate(tempDate.getDate() + 1))
            : tempDate;
        setResponseEndTime(temp);

        return temp;
      } else if (TSIssueWorkEnd < TSIssueEnd) {
        const tempDate = new Date(
          TSIssueWorkEnd + cutOutTime + (TSIssueEnd - TSIssueWorkEnd)
        );
        const temp =
          tempDate.getDay() === 0
            ? new Date(tempDate.setDate(tempDate.getDate() + 1))
            : tempDate;
        setResponseEndTime(temp);
        return temp;
      }
    }
  };

  const calculateEndTimeResolution = (
    StartDateAndTime,
    isHours,
    resolutionTime,
    mgStart,
    mgEnd,
    isWorkingHours = 0
  ) => {
    const DateIssueCreated = new Date(StartDateAndTime);
    const TSIssueCreated = new Date(DateIssueCreated).getTime();
    const [mgStartHours, mgStartMinutes, mgStartSeconds] = mgStart.split(":");
    const [mgEndHours, mgEndMinutes, mgEndSeconds] = mgEnd.split(":");

    const TSMgTmrwStart = new Date(
      2023,
      0,
      2,
      +mgStartHours,
      +mgStartMinutes,
      +mgStartSeconds
    );
    const TSMgStart = new Date(
      2023,
      0,
      1,
      +mgStartHours,
      +mgStartMinutes,
      +mgStartSeconds
    );
    const TSMgEnd = new Date(
      2023,
      0,
      1,
      +mgEndHours,
      +mgEndMinutes,
      +mgEndSeconds
    );
    const cutOutTime = Math.abs(TSMgEnd - TSMgTmrwStart);
    const workingTime = Math.abs(TSMgEnd - TSMgStart);

    const DateIssueWorkEnd = new Date(
      DateIssueCreated.getFullYear(),
      DateIssueCreated.getMonth(),
      DateIssueCreated.getDate(),
      +mgEndHours,
      +mgEndMinutes,
      +mgEndSeconds
    );
    const TSIssueWorkEnd = DateIssueWorkEnd.getTime();

    if (isHours === 1) {
      // console.log("laksh", "hours");
      resolutionTime = "9:00"; //if it is working hours then working time hours should be placed here
      const [splittedResponseHours, splittedResponseMinutes] =
        resolutionTime.split(":");
      const TSIssueEnd =
        TSIssueCreated +
        (+splittedResponseHours + +splittedResponseMinutes / 60) *
          60 *
          60 *
          1000;
      const DateIssueEnd = new Date(TSIssueEnd);
      if (TSIssueWorkEnd > TSIssueEnd) {
        const tempDate = new Date(TSIssueEnd);
        const temp =
          tempDate.getDay() === 0
            ? new Date(tempDate.setDate(tempDate.getDate() + 1))
            : tempDate;
        // console.log("sri", tempDate.getDay());
        setResolutionEndTime(temp);
        return temp;
        // return new Date(TSIssueEnd)
      } else if (TSIssueWorkEnd < TSIssueEnd) {
        const tempDate = new Date(
          TSIssueWorkEnd + cutOutTime + (TSIssueEnd - TSIssueWorkEnd)
        );
        const temp =
          tempDate.getDay() === 0
            ? new Date(tempDate.setDate(tempDate.getDate() + 1))
            : tempDate;
        // setResolutionEndTime(temp);
        return temp;
      }
    } else if (isHours === 0) {
      // console.log("laksh", "days", resolutionTime);
      const hours_they_need = workingTime * +resolutionTime;
      let TSIssueEnd = TSIssueCreated + workingTime;
      let DateIssueEnd = new Date(TSIssueEnd);

      if (TSIssueWorkEnd > TSIssueEnd) {
        const tempDate = new Date(TSIssueEnd);
        const temp =
          tempDate.getDay() === 0
            ? new Date(tempDate.setDate(tempDate.getDate() + 1))
            : tempDate;
        setResolutionEndTime(temp);

        return temp;
      } else if (TSIssueWorkEnd < TSIssueEnd) {
        for (let i = 1; i <= resolutionTime; i++) {
          let DateUpIssueCreated = new Date(
            TSIssueWorkEnd + cutOutTime + (TSIssueEnd - TSIssueWorkEnd)
          );
          let TSUpIssueCreated = DateUpIssueCreated.getTime();
          TSIssueEnd = TSUpIssueCreated + workingTime;
          DateIssueEnd = new Date(TSIssueEnd);
        }

        const tempDate = new Date(
          TSIssueWorkEnd + cutOutTime + (TSIssueEnd - TSIssueWorkEnd)
        );
        const temp =
          tempDate.getDay() === 0
            ? new Date(tempDate.setDate(tempDate.getDate() + 1))
            : tempDate;
        // setResolutionEndTime(temp);
        return temp;
      }
    }
  };
 

  //////////////////////////////
  useEffect(() => {
    // Check if the user is already present in localStorage
    const userExists = localStorage.getItem("acsRightUser");
    const hasReloaded = localStorage.getItem("hasReloaded");

    // If the user does not exist and has not reloaded yet
    if (!userExists && !hasReloaded) {
      // Set the reload flag in localStorage
      localStorage.setItem("hasReloaded", "true");
      // Reload the page
      window.location.reload();
      window.location.reload();
    }

    // Clean up: remove the reload flag if user is found
    if (userExists) {
      localStorage.removeItem("hasReloaded");
    }
  }, []);

  const [actualLoading, setActualLoading] = useState(true);
  const [HeaderactualLoading, setHeaderActualLoading] = useState(true);

  useEffect(() => {
    FetchThoseTickets();
  }, [modalClicked, editModalClicked == false, boxValue]);

  useEffect(()=>{
    const hasAccessTOken = localStorage.getItem('DepthasAccess');
    
    setTimeout(()=>{ 
    if(( hasAccessTOken == 'true')) {
      // setHeaderActualLoading(false);
      // console.log("122")
      localStorage.setItem('DepthasAccess', 'false'); // Update localStorage

        FetchThoseTickets();
        // window.location.reload();

    }
  },[6000])
    
  },[userDeptHeadPropertyID])


  const FetchThoseTickets =async()=>{
    setIsLoading(true);
    dispatch(screenLoading(true));

    const controller = new AbortController();
    const user = JSON.parse(localStorage.getItem("user"));

    getMethod(`user-management/api/v1/user/${user?.id}`)
      .then((res) => {
        // console.log(res.data.data,"mehtodism")
        setAcsRightUser(res.data.data);

        localStorage.setItem("acsRightUser", JSON.stringify(res.data.data));
      })
      .catch((err) => console.log(err));

    const storedAcessRightsData = JSON.parse(
      localStorage.getItem("acsRightUser")
    );
    const TOgetUsersRoles = JSON.parse(localStorage.getItem("user"));

    // console.log(TOgetUsersRoles?.user_org_depts?.organization_department_role,"myss");

     

    // const filteredDeptRole = TOgetUsersRoles?.user_org_depts?.filter(item => 
    //   item.organization_department_role.dep_role_name === "Manager" || 
    //   item.organization_department_role.dep_role_name === "Department Head"
    // );
    const myUserOrg= TOgetUsersRoles?.user_org_depts?.map((c)=>c);

    const myUserOrgDept=myUserOrg?.map((d)=>d?.organization_department_role)

    const MyuserOrgDepRoles=myUserOrgDept?.map((d)=>d.dep_role_name)

    // console.log(MyuserOrgDepRoles,"filteredDeptRole")

    const allowedRoles = ["Admin", "Super Admin", "Sub Admin"];
    

    const userRoles = TOgetUsersRoles.roles.map((c) => c.role_name);

    // Check if the user has access
    // const hasAccess = userRoles.some((role) => allowedRoles.includes(role)) ;

    const hasAccess = userRoles.some((role) => allowedRoles.includes(role)) 
    // || 
    //                   MyuserOrgDepRoles.some((role) => allowedRoles.includes(role));
    // console.log(hasAccess, "mklutuewerwe");

    // console.log(hasAccess,"hasaccess")


    // Step 2: If `hasAccess` is 'true', change it to 'false' and reload the page
   


    
    function convertDecimalToHoursAndMinutes(decimalHours) {
      // Get the total hours
      const hours = Math.floor(decimalHours);
      // Get the remaining minutes by multiplying the decimal part by 60
      const minutes = Math.round((decimalHours - hours) * 60);

      return {
        hours,
        minutes,
      };
    }

    function timeToSeconds(time) {
      const parts = time.split(":").map(Number);
      if (parts.length === 2) {
        return parts[0] * 60 + parts[1]; // For hours and minutes
      } else if (parts.length === 3) {
        return parts[0] * 24 * 3600 + parts[1] * 3600 + parts[2]; // For days, hours, and seconds
      }
      return 0;
    }

    // getall issue
    getMethod("issue-management/api/v1/issue", controller.signal)
      .then((res) => {
        let apiData =
          user?.roles[0]?.role_name === "Sales Executive"
            ? res?.data?.data.filter((issue) => issue.user_id === user.id)
            : res?.data?.data;


        if (!hasAccess) {

          const DeptHeadCheck=["Department Head","Head of Department"];
            const DeptRoleCheck=MyuserOrgDepRoles.some((role) => DeptHeadCheck?.includes(role));
            // console.log(DeptRoleCheck,"tvmfalse");
            // console.log(storedAcessRightsData,"storedAcessRightsData")
 
            const departmentHead = storedAcessRightsData?.user_org_depts?.find(
              (item) => item.organization_department_role.dep_role_name === 'Department Head'
            );
 
            const departmentHeadId = departmentHead ? departmentHead.organization_department_role.organization_department_id : null;


            // console.log(departmentHeadId,"departmentHeadId")


          if(departmentHeadId==null) {
            const userPropertyIds = storedAcessRightsData.property_details.map(
              (k) => k.id
            );
            setUserDeptHeadPropertyIDHeader(userPropertyIds);
            // console.log("iamnull")
          } else {
            getMethod(`user-management/api/v1/gettingDeptbasedUserProperties/${departmentHeadId}`)
            .then((res) => {
              
              // console.log(res.data.data ,"minedept")
              setDeptHeadProperties(res?.data?.data)
              // console.log("iamnullnot")
              setUserDeptHeadPropertyIDHeader(DeptHeadProperties)
         
            })
            .catch((err) => console.log(err));


          }
          
          
          // const userPropertyIds = storedAcessRightsData.property_details?.map(
          //   (k) => k.id
          // );

          //  apiData = apiData?.filter((issue) =>
          //   UserDeptHeadPropertyIDHeader?.includes(issue.property_id)
          // );
        }
        // window.location.reload();
        // console.log(apiData, "Api data");
        if (res?.data?.status) {
          setActualLoading(false);
          // window.location.reload()

          // ----------->new
          apiData.forEach((actEle, index) => {
            const responseSLAStatic = calculateSLA(
              actEle?.issue_sub_category_id !== null
                ? actEle?.issue_sub_category.is_response_hour
                : actEle?.issue_category?.is_response_hour,
              actEle?.issue_sub_category_id !== null
                ? actEle?.issue_sub_category.response_period
                : actEle?.issue_category?.response_period
            );

            const resolutionSLAStatic = calculateSLA(
              actEle?.issue_sub_category_id !== null
                ? actEle?.issue_sub_category.is_resolution_hour
                : actEle?.issue_category?.is_resolution_hour,
              actEle?.issue_sub_category_id !== null
                ? actEle?.issue_sub_category.resolution_period
                : actEle?.issue_category?.resolution_period
            );
           
            //SETTING STATIC  SLA
            actEle.responseSLAStatic = responseSLAStatic;
            actEle.resolutionSLAStatic = resolutionSLAStatic;
            // console.log(resolutionSLAStatic,"res_staticzz")
            actEle.isDelayed = false;
            //  if(actEle.id===624) console.log('laksh2',actEle.responseSLAStatic)
            //ADDING DEFAULT RESPONSED & SOLVED TIME
            actEle.responsedTime = new Date();
            actEle.solvedTime = new Date();
            actEle.isIssueAssigned = false;
            // actEle.isIssueInProgress = false;
            actEle?.issue_action?.forEach((ele, i, arr) => {
              if (ele.issue_status == 1) {
                const resp = calculateEndTimeResponse(
                  // issue_sub_category_id null
                  actEle.created_at,
                  actEle?.issue_sub_category_id !== null
                    ? actEle?.issue_sub_category.is_response_hour
                    : actEle?.issue_category?.is_response_hour,
                  actEle?.issue_sub_category_id !== null
                    ? actEle?.issue_sub_category.response_period
                    : actEle?.issue_category?.response_period,
                  actEle?.issue_sub_category_id !== null
                    ? actEle?.issue_sub_category.mg_start_time
                    : actEle?.issue_category?.mg_start_time,
                  actEle?.issue_sub_category_id !== null
                    ? actEle?.issue_sub_category.mg_end_time
                    : actEle?.issue_category?.mg_end_time
                );
                // if (actEle.id === 624) console.log("laksh3", resp);
              } else if (ele.issue_status == 2) {
                // CHANGE RESPONSED TIME AFTER ASSIGNED
                actEle.responsedTime = new Date(ele.created_at);
                // STOP TIMER IN TABLE
                actEle.isIssueAssigned = true;
                // responsedTime = new Date(ele.created_at);
              }  
              
              else if (ele.issue_status == 4) {
                // CHANGE SOLVED TIME AFTER RESOLVED
                actEle.solvedTime = new Date(ele.created_at);
              } else if (ele.issue_status == 5) {
                // RESET TO RESPONSED & SOLVED TIME AFTER RE-OPEN
                actEle.responsedTime = new Date();
                actEle.solvedTime = new Date();
                const responseArray = arr.flatMap((ele) =>
                  ele.issue_status == 2 ? ele : []
                );
                const resolutionArray = arr.flatMap((ele) =>
                  ele.issue_status == 4 ? ele : []
                );
                // logic for remaining in table
                // RESET ASSIGNED STATUS
                actEle.isIssueAssigned = false;
                // const isAssignedAfterReOpen = arr.findLast((ele) => ele.issue_status == 2);
                // const isResolvedAfterReOpen = arr.findLast((ele) => ele.issue_status == 4);

                if (responseArray[1]) {
                  actEle.responsedTime = new Date(responseArray[1].created_at);
                  actEle.isIssueAssigned = true;
                }
                if (resolutionArray[1]) {
                  actEle.solvedTime = new Date(resolutionArray[1].created_at);
                }
              }
            });

            actEle?.issue_action?.forEach((action, i, arr) => {
              // if (action.issue_status == 3) {
                // console.log(actEle.created_at,"kkkkllll")
                const IssueCreatedON = actEle?.created_at ;
                // const IssueCreatedON = actEle?.progress_start_time ? actEle?.progress_start_time  : actEle?.created_at ;
                // console.log(actEle.issue_action.find(action => action?.issue_status === 2),"qqqq")
                const respondt = actEle.issue_action.find(
                  (action) => action?.issue_status === 2
                );
                //  console.log(respondt.created_at,"qwqwq")
                const FirstResponse = respondt?.created_at;
                const mgstartTime =
                  actEle?.issue_sub_category === null
                    ? actEle?.issue_category?.mg_start_time
                    : actEle?.issue_sub_category?.mg_start_time;
                const mgEndTime =
                  actEle?.issue_sub_category === null
                    ? actEle?.issue_category?.mg_end_time
                    : actEle?.issue_sub_category?.mg_end_time;
                const ResponsePeriod =
                  actEle?.issue_sub_category === null
                    ? actEle?.issue_category?.response_period
                    : actEle?.issue_sub_category?.response_period;
                const IsDayorHour =
                  actEle?.issue_sub_category === null
                    ? 1
                    : actEle?.issue_sub_category?.is_response_day;

                    // console.log(IssueCreatedON,FirstResponse,mgstartTime,mgEndTime,"myspeed",actEle.id)

                const MySLAEffieciency = calculateActualTimeDifference(
                  IssueCreatedON,
                  FirstResponse,
                  mgstartTime,
                  mgEndTime
                );
                // console.log(MySLAEffieciency,"msqlone",actEle.id)
                const result =convertDecimalToHoursAndMinutes(MySLAEffieciency);

                const ActualTimeTken = timeToSeconds(ResponsePeriod);
                const ExpectedTimeTaken = timeToSeconds(MySLAEffieciency);

                // console.log(ActualTimeTken, ExpectedTimeTaken,"jacksone",actEle.id)


             
                let efficiency;

                if (ExpectedTimeTaken <= 0) {
                  efficiency = 0; // Prevent division by zero or negative response time
                } else {
                  efficiency = (ExpectedTimeTaken / ActualTimeTken) * 100; // Calculate efficiency
                }
                efficiency = Math.min(efficiency, 200);
                efficiency = Math.max(efficiency, 0);

                actEle.responseEfficiency = efficiency.toFixed(2);
                
                // ↑ ↓
              // }
            });



            //resolution efficicency
            actEle?.issue_action?.forEach((action, i, arr) => {
              
             
              
              const respondt = actEle?.issue_action?.find(
                (action) => action?.issue_status == 4
              );

              // Find the assigned action with issue_status 2
              const assignedDate = actEle?.issue_action?.find(
                (action) => action?.issue_status == 2
              );

              // console.log(actEle,"myeleopjkl")

              // console.log(assignedDate ,"assignedDate");
              // console.log(respondt ,"respondt");

              const FirstResponse = respondt?.created_at; // Use optional chaining
              const IssueCreatedON = assignedDate?.created_at; // Use optional chaining

              // console.log(FirstResponse, IssueCreatedON,"hjkl", actEle.id)

              // Proceed only if both FirstResponse and IssueCreatedON are defined
              if (FirstResponse !=="" && IssueCreatedON !=="") { 
                const mgstartTime =
                  actEle?.issue_sub_category === null
                    ? actEle?.issue_category?.mg_start_time
                    : actEle?.issue_sub_category?.mg_start_time;

                const mgEndTime =
                  actEle?.issue_sub_category === null
                    ? actEle?.issue_category?.mg_end_time
                    : actEle?.issue_sub_category?.mg_end_time;

                const ResolutionPeriod =
                  actEle?.issue_sub_category === null
                    ? actEle?.issue_category?.resolution_period
                    : actEle?.issue_sub_category?.resolution_period;

                const IsDayorHour =
                  actEle?.issue_sub_category === null
                    ? 1
                    : actEle?.issue_sub_category?.is_response_day;

                const MyResolutionEfficiency = calculateActualTimeDifference(
                  IssueCreatedON,
                  FirstResponse,
                  mgstartTime,
                  mgEndTime
                );
                // console.log(MyResolutionEfficiency,"msql",actEle.id)


                const result = convertDecimalToHoursAndMinutes(
                  MyResolutionEfficiency
                );

                const ActualTimeTken = timeToSeconds(ResolutionPeriod);
                const ExpectedTimeTaken = timeToSeconds(MyResolutionEfficiency);


                // console.log(ActualTimeTken, ExpectedTimeTaken,"jacks",actEle.id)

                // console.log(ActualTimeTken, ExpectedTimeTaken, "stdtime");

                let efficiency;

                if (ExpectedTimeTaken <= 0) {
                  efficiency = 0; // Prevent division by zero or negative response time
                } else {
                  efficiency = (ExpectedTimeTaken / ActualTimeTken) * 100; // Calculate efficiency
                }
                efficiency = Math.min(efficiency, 200);
                efficiency = Math.max(efficiency, 0);

                // console.log(efficiency,"rkknagarxs")

                actEle.resolutionEfficiency = efficiency.toFixed(2);
                // console.log(actEle.resolutionEfficiency,"loops")
                
             
                // console.log(
                //   actEle.resolutionEfficiency,
                //   ActualTimeTken,
                //   ExpectedTimeTaken,
                //   "madurai"
                // );
              }
            // }
            });

            // console.log(actEle,"nvdia",actEle.id)
            // CALCULATING DELAY PART
            const [timeStringResponse, amIDelayResponse] =
              calculateDelayResponses(
                actEle.response_end_time,
                actEle.responsedTime,
                actEle?.issue_sub_category_id !== null
                  ? actEle?.issue_sub_category.mg_start_time
                  : actEle?.issue_category?.mg_start_time,
                actEle?.issue_sub_category_id !== null
                  ? actEle?.issue_sub_category.mg_end_time
                  : actEle?.issue_category?.mg_end_time,
                index
              );
            // if(actEle.id===624)console.log('laksh4',actEle.response_end_time,actEle.responsedTime,timeStringResponse)
            // CALCULATE TIME DELAY FOR RESPONSE
            const [timeStringResolution, amIDelayResolution] =
              calculateDelayResponses(
                actEle.resolution_end_time,
                actEle.solvedTime,
                actEle?.issue_sub_category_id !== null
                  ? actEle?.issue_sub_category.mg_start_time
                  : actEle?.issue_category?.mg_start_time,
                actEle?.issue_sub_category_id !== null
                  ? actEle?.issue_sub_category.mg_end_time
                  : actEle?.issue_category?.mg_end_time,
                index
              );

            // ADD CALCULATED DELAY STRINGS
            actEle.timeStringResponse = {
              isItDelay: amIDelayResponse,
              delayTime: timeStringResponse,
            };
            // console.log(amIDelayResponse,timeStringResponse,"avasrle",actEle.id)
            actEle.timeStringResolution = {
              isItDelay: amIDelayResolution,
              delayTime: timeStringResolution,
            };
            // console.log(amIDelayResolution,timeStringResolution ,"google")

            // FOR WORKING DAYS SET WHETHER DELAY OR NOT
            actEle.isDelayed =
              amIDelayResponse === true && amIDelayResolution === true
                ? true
                : false;
          });

          const filteredItems = apiData.filter((item) => item.status === 1);
          const totalDays = filteredItems.reduce(
            (acc, item) =>
              acc +
              (!item.issue_action.length
                ? Math.ceil(
                    Math.abs(new Date() - new Date(item.created_at)) /
                      (1000 * 60 * 60 * 24)
                  )
                : 0),
            0
          );
          const averageDays = (totalDays / filteredItems.length).toFixed(1);

          // console.log(apiData,"apiDatas")

          const sortedArray = apiData.sort((a, b) => b?.id - a?.id);
          let filteredData = sortedArray?.filter((data, index) => {
            if (boxValue === 1) {
              return data?.status === 1 || data?.status === 5;
            } else if (boxValue === 2) {
              return data?.status === 2;
            } else if (boxValue === 3) {
              // dispatch(screenLoading(true));
              return data?.isDelayed == true;
            } else if (boxValue === 4) {
              return data?.status === 6;
            } else if (boxValue === 5) {
              // return data?.status === 4 || data?.status === 3;
              return data?.status === 4;
            } else if (boxValue === 7) {
              return data;
            } else if (boxValue === 8) {
              return data?.status === 3;
            } else return data?.status !== 6;
          });

          // console.log(filteredData.map((c)=>c.property_id),"mkllghjinsdjkfhsfd");
          const storedAcessRightsData = JSON.parse(
            localStorage.getItem("acsRightUser")
          );
          const TOgetUsersRoles = JSON.parse(localStorage.getItem("user"));
          // console.log(storedAcessRightsData.property_details.map((k)=>k.id),"jdfgjhsdgjhsdf")
          // console.log(TOgetUsersRoles.roles.map((c)=>c.role_name),"togetroles");
         
          const myUserOrg= TOgetUsersRoles?.user_org_depts?.map((c)=>c);

          const myUserOrgDept=myUserOrg?.map((d)=>d?.organization_department_role)
        
          const MyuserOrgDepRoles=myUserOrgDept?.map((d)=>d.dep_role_name)
        
          // console.log(MyuserOrgDepRoles,"filteredDeptRole")
        
         
          const allowedRoles = ["Admin", "Super Admin", "Sub Admin"];
  

         const userRoles = TOgetUsersRoles.roles.map((c) => c.role_name);

         const hasAccess = userRoles.some((role) => allowedRoles.includes(role))
          // || 
          //    MyuserOrgDepRoles.some((role) => allowedRoles.includes(role));


           const hasAccessTT = localStorage.getItem('hasAccess');

             if (hasAccessTT === 'true' && !hasAccess) {
              // console.log("Access is currently true. Changing it to false and reloading...");
              localStorage.setItem('hasAccess', 'false'); // Update localStorage
              window.location.reload(); // Reload the page to apply changes
            } else {
              console.log("Access is already false or not set yet.");
            }

            const DeptHeadCheck=["Department Head","Head of Department"];
            const DeptRoleCheck=MyuserOrgDepRoles.some((role) => DeptHeadCheck?.includes(role));
            // console.log(DeptRoleCheck,"tvmfalse");
            // console.log(storedAcessRightsData,"storedAcessRightsData")

              // Find the object where dep_role_name is 'Department Head'
            const departmentHead = storedAcessRightsData?.user_org_depts?.find(
              (item) => item.organization_department_role.dep_role_name === 'Department Head'
            );

            // Store the organization_department_id in a variable
            const departmentHeadId = departmentHead ? departmentHead?.organization_department_role?.organization_department_id : null;


            // console.log(departmentHeadId,"departmentHeadId")

           getMethodAsync(`user-management/api/v1/gettingDeptbasedUserProperties/${departmentHeadId}`)
            .then((res) => { 
              // console.log(res.data.data,"mehtodism")
              // setAcsRightUser(res.data.data);
              // console.log(res.data.data ,"minedept")
              setDeptHeadPropertiesForHeader(res.data.data) 
             localStorage.setItem('DepthasAccess', 'false'); // Update localStorage

             cosntantIssueFunction(res.data.data);
              // console.log(DeptHeadPropertiesForHeader,"deptheader")
          
              // setHeaderActualLoading(true);
              
        })
        .catch((err) => console.log(err));


        const cosntantIssueFunction =(directlyGet)=>{

          // console.log(directlyGet.length,"depthigher")
          if(directlyGet.length==0 && departmentHeadId==null && !hasAccess) {

          //  console.log(apiData.length)

            // console.log("iamnullofDepartment")
            const userPropertyIds = storedAcessRightsData.property_details.map(
              (k) => k.id
            );
            setUserDeptHeadPropertyID(userPropertyIds);
            // console.log("iamnull")

            const matchingProperties = filteredData.filter((c) =>
              userPropertyIds?.includes(c.property_id)
            );

            apiData = apiData?.filter((issue) =>
              userPropertyIds?.includes(issue.property_id)
            );
            // console.log(matchingProperties.length,"matchingcg")
            setmatchrProp(matchingProperties);
            dispatch(issuesListData(matchingProperties));
            setHeaderActualLoading(true);
            setAccesible(true);
            dispatch(issuesMasterData(apiData));
            dispatch(screenLoading(false));
            setIsLoading(false);

            dispatch(
              notificationsData([
                {
                  name: "Unassigned Tickets",
                  count: apiData?.filter((d) => d.status == 1 || d.status == 5)
                    .length,
                    
                  value: 1,
                },
                {
                  name: "Assigned Tickets",
                  count: apiData?.filter((d) => d.status == 2).length,
                  value: 2,
                },
                {
                  name: "In-Progress Tickets",
                  count: apiData?.filter((d) => d.status == 3).length,
                  value: 8,
                },
                {
                  name: "AVG Days Open",
                  count: isNaN(averageDays) ? 0 : parseFloat(averageDays),
                  //value: 6,
                },
  
                {
                  name: "Closed Tickets",
                  count: apiData?.filter((d) => d.status == 6).length,
                  value: 4,
                },
  
                {
                  name: "Confirmation Pending",
                  count: apiData?.filter(
                    // (d) => d.status == 4 || d?.status == 3
                    (d) => d.status == 4
                  ).length,
                  value: 5,
                },
                {
                  name: "Total Tickets",
                  count: apiData?.length,
                  value: 7,
                },
                {
                  name: "Delayed Tickets",
                  count: apiData?.filter((d) => d.isDelayed == true).length,
                  value: 3,
                },
                {
                  name: "Response Efficiency",
                  count: (() => {
                    const filteredData = apiData?.filter((d) =>
                      d.issue_action?.some((action) => action.issue_status === 2)
                    );
                
                    if (!filteredData || filteredData.length === 0) return 0; // Return 0 if no data
                
                    const totalEfficiency = filteredData.reduce((acc, curr) => {
                      // Check if responseEfficiency exists and is a valid number
                      if (curr?.responseEfficiency && !isNaN(curr.responseEfficiency)) {
                        return acc + parseFloat(curr.responseEfficiency); // Add it to the total if valid
                      }
                      return acc; // Skip if it's not a valid number
                    }, 0);
                
                    // Calculate average efficiency (as a raw number, not a percentage)
                    const averageEfficiency = totalEfficiency / filteredData.length;
                
                    // Ensure that the value is a valid number (avoid NaN) and return it
                    return isNaN(averageEfficiency) ? 0 : Math.round(averageEfficiency); 
                  })(),
                  value: 7, // Keep your value logic as needed
                },
                
                {
                  name: "Resolution Efficiency",
                  count: (() => {
                    const filteredData = apiData?.filter((d) =>
                      d.issue_action?.some((action) => action.issue_status === 4)
                    );
                
                    if (!filteredData || filteredData.length === 0) return 0; // Return 0 if no data
                
                    const totalEfficiency = filteredData.reduce((acc, curr) => {
                      // Check if resolutionEfficiency exists and is a valid number
                      if (curr?.resolutionEfficiency && !isNaN(curr.resolutionEfficiency)) {
                        return acc + parseFloat(curr.resolutionEfficiency); // Add it to the total if valid
                      }
                      return acc; // Skip if it's not a valid number
                    }, 0);
                
                    // Calculate average efficiency (as a raw number, not a percentage)
                    const averageEfficiency = totalEfficiency / filteredData.length;
                
                    // Ensure that the value is a valid number (avoid NaN) and return it
                    return isNaN(averageEfficiency) ? 0 : Math.round(averageEfficiency); 
                  })(),
                  value: 7, // Keep your value logic as needed
                }
                
                
              ])
            );
        
            // setHeaderActualLoading(false);
          } else {
            
            // console.log("iamnullofDepartmentno")
            // console.log(directlyGet,"directlyGet")
            // console.log("iamnullnot")
            setUserDeptHeadPropertyID(directlyGet)
           localStorage.setItem('DepthasAccess', 'false'); // Update localStorage
          }
          // console.log(userDeptHeadPropertyID,"matchingcgLvelone")
          
       
          if (hasAccess) {
            // Dispatch the action for when the user has access
            setAccesible(true);
            dispatch(issuesListData(filteredData));


            dispatch(issuesMasterData(apiData));
            dispatch(screenLoading(false));
            setIsLoading(false);
            // console.log(apiData.length,"myzero")
          dispatch(
            notificationsData([
              {
                name: "Unassigned Tickets",
                count: apiData?.filter((d) => d.status == 1 || d.status == 5)
                  .length,
                  
                value: 1,
              },
              {
                name: "Assigned Tickets",
                count: apiData?.filter((d) => d.status == 2).length,
                value: 2,
              },
              {
                name: "In-Progress Tickets",
                count: apiData?.filter((d) => d.status == 3).length,
                value: 8,
              },
              {
                name: "AVG Days Open",
                count: isNaN(averageDays) ? 0 : parseFloat(averageDays),
                //value: 6,
              },

              {
                name: "Closed Tickets",
                count: apiData?.filter((d) => d.status == 6).length,
                value: 4,
              },

              {
                name: "Confirmation Pending",
                count: apiData?.filter(
                  // (d) => d.status == 4 || d?.status == 3
                  (d) => d.status == 4
                ).length,
                value: 5,
              },
              {
                name: "Total Tickets",
                count: apiData?.length,
                value: 7,
              },
              {
                name: "Delayed Tickets",
                count: apiData?.filter((d) => d.isDelayed == true).length,
                value: 3,
              },
              {
                name: "Response Efficiency",
                count: (() => {
                  const filteredData = apiData?.filter((d) =>
                    d.issue_action?.some((action) => action.issue_status === 2)
                  );
              
                  if (!filteredData || filteredData.length === 0) return 0; // Return 0 if no data
              
                  const totalEfficiency = filteredData.reduce((acc, curr) => {
                    // Check if responseEfficiency exists and is a valid number
                    if (curr?.responseEfficiency && !isNaN(curr.responseEfficiency)) {
                      return acc + parseFloat(curr.responseEfficiency); // Add it to the total if valid
                    }
                    return acc; // Skip if it's not a valid number
                  }, 0);
              
                  // Calculate average efficiency (as a raw number, not a percentage)
                  const averageEfficiency = totalEfficiency / filteredData.length;
              
                  // Ensure that the value is a valid number (avoid NaN) and return it
                  return isNaN(averageEfficiency) ? 0 : Math.round(averageEfficiency); 
                })(),
                value: 7, // Keep your value logic as needed
              },
              
              {
                name: "Resolution Efficiency",
                count: (() => {
                  const filteredData = apiData?.filter((d) =>
                    d.issue_action?.some((action) => action.issue_status === 4)
                  );
              
                  if (!filteredData || filteredData.length === 0) return 0; // Return 0 if no data
              
                  const totalEfficiency = filteredData.reduce((acc, curr) => {
                    // Check if resolutionEfficiency exists and is a valid number
                    if (curr?.resolutionEfficiency && !isNaN(curr.resolutionEfficiency)) {
                      return acc + parseFloat(curr.resolutionEfficiency); // Add it to the total if valid
                    }
                    return acc; // Skip if it's not a valid number
                  }, 0);
              
                  // Calculate average efficiency (as a raw number, not a percentage)
                  const averageEfficiency = totalEfficiency / filteredData.length;
              
                  // Ensure that the value is a valid number (avoid NaN) and return it
                  return isNaN(averageEfficiency) ? 0 : Math.round(averageEfficiency); 
                })(),
                value: 7, // Keep your value logic as needed
              }
              
              
            ])
          );
          } 
          if(directlyGet?.length>0) {
                apiData = apiData?.filter((issue) =>
            directlyGet?.includes(issue.property_id)
          );

          // console.log("yesimpresentherealso");

            setAccesible(false);
            setUserDeptHeadPropertyID(directlyGet)

            const validUserDeptHeadPropertyID = Array.isArray(userDeptHeadPropertyID) ? userDeptHeadPropertyID : [];

            // console.log(filteredData,"ftdData")
            // Filter the properties based on the userDeptHeadPropertyID
            const matchingProperties = filteredData.filter((c) =>
              directlyGet?.includes(c.property_id)
            );
            // console.log(matchingProperties,"matchingcg")
            setmatchrProp(matchingProperties);
            dispatch(issuesListData(matchingProperties));
            setHeaderActualLoading(true);


            dispatch(issuesMasterData(apiData));
            dispatch(screenLoading(false));
            setIsLoading(false);

            dispatch(
              notificationsData([
                {
                  name: "Unassigned Tickets",
                  count: apiData?.filter((d) => d.status == 1 || d.status == 5)
                    .length,
                    
                  value: 1,
                },
                {
                  name: "Assigned Tickets",
                  count: apiData?.filter((d) => d.status == 2).length,
                  value: 2,
                },
                {
                  name: "In-Progress Tickets",
                  count: apiData?.filter((d) => d.status == 3).length,
                  value: 8,
                },
                {
                  name: "AVG Days Open",
                  count: isNaN(averageDays) ? 0 : parseFloat(averageDays),
                  //value: 6,
                },
  
                {
                  name: "Closed Tickets",
                  count: apiData?.filter((d) => d.status == 6).length,
                  value: 4,
                },
  
                {
                  name: "Confirmation Pending",
                  count: apiData?.filter(
                    // (d) => d.status == 4 || d?.status == 3
                    (d) => d.status == 4
                  ).length,
                  value: 5,
                },
                {
                  name: "Total Tickets",
                  count: apiData?.length,
                  value: 7,
                },
                {
                  name: "Delayed Tickets",
                  count: apiData?.filter((d) => d.isDelayed == true).length,
                  value: 3,
                },
                {
                  name: "Response Efficiency",
                  count: (() => {
                    const filteredData = apiData?.filter((d) =>
                      d.issue_action?.some((action) => action.issue_status === 2)
                    );
                
                    if (!filteredData || filteredData.length === 0) return 0; // Return 0 if no data
                
                    const totalEfficiency = filteredData.reduce((acc, curr) => {
                      // Check if responseEfficiency exists and is a valid number
                      if (curr?.responseEfficiency && !isNaN(curr.responseEfficiency)) {
                        return acc + parseFloat(curr.responseEfficiency); // Add it to the total if valid
                      }
                      return acc; // Skip if it's not a valid number
                    }, 0);
                
                    // Calculate average efficiency (as a raw number, not a percentage)
                    const averageEfficiency = totalEfficiency / filteredData.length;
                
                    // Ensure that the value is a valid number (avoid NaN) and return it
                    return isNaN(averageEfficiency) ? 0 : Math.round(averageEfficiency); 
                  })(),
                  value: 7, // Keep your value logic as needed
                },
                
                {
                  name: "Resolution Efficiency",
                  count: (() => {
                    const filteredData = apiData?.filter((d) =>
                      d.issue_action?.some((action) => action.issue_status === 4)
                    );
                
                    if (!filteredData || filteredData.length === 0) return 0; // Return 0 if no data
                
                    const totalEfficiency = filteredData.reduce((acc, curr) => {
                      // Check if resolutionEfficiency exists and is a valid number
                      if (curr?.resolutionEfficiency && !isNaN(curr.resolutionEfficiency)) {
                        return acc + parseFloat(curr.resolutionEfficiency); // Add it to the total if valid
                      }
                      return acc; // Skip if it's not a valid number
                    }, 0);
                
                    // Calculate average efficiency (as a raw number, not a percentage)
                    const averageEfficiency = totalEfficiency / filteredData.length;
                
                    // Ensure that the value is a valid number (avoid NaN) and return it
                    return isNaN(averageEfficiency) ? 0 : Math.round(averageEfficiency); 
                  })(),
                  value: 7, // Keep your value logic as needed
                }
                
                
              ])
            );
          }
          

         
          // console.log(directlyGet,"directlyGet")
        }

          // console.log(apiData,"apooooiiii")


          //   apiData = apiData?.filter((issue) =>
          //   UserDeptHeadPropertyIDHeader?.includes(issue.property_id)
          // );

        } else {
          alert(res?.data?.message);
          setIsLoading(false);
          dispatch(screenLoading(false));
        }
        // setHeaderActualLoading(false);
      })
      .catch((err) => {
        dispatch(issuesListData([]));
        dispatch(screenLoading(false));
        setIsLoading(false);
      });
    return () => {
      controller.abort();
    };

  }

  useEffect(()=>{
    const hasAccessTT = localStorage.getItem('hasAccess');

    if (hasAccessTT == 'true') {
      setTimeout(()=>{
      // console.log("Access is currently true. Changing it to false and reloading...");
      localStorage.setItem('hasAccess', 'false'); // Update localStorage
      window.location.reload();  
    },[6000])
    } else {
      // console.log("Access is already false or not set yet.");
    }
  },[])

  const notiData = useSelector((state) => state?.modal?.notificationData);
  const isFullScreen = useSelector((state) => state?.modal?.fullScreenToggle);

  const floatingVersionStyles = {
    position: "fixed",
    bottom: "5px",
    right: "5px",
    padding: "3px 7px",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    color: "#fff",
    fontSize: "10px",
  };

  const pieChartData = [
    ["Tickets", "Reported"],
    [notiData[0]?.name, notiData[0]?.count],
    [notiData[1]?.name, notiData[1]?.count],
    [notiData[2]?.name, notiData[2]?.count],
    [notiData[3]?.name, notiData[3]?.count],
  ];

  const barChartData = [
    [
      "Element",
      "Tickets",
      { role: "style" },
      {
        sourceColumn: 0,
        role: "annotation",
        type: "string",
        calc: "stringify",
      },
    ],
    [notiData[0]?.name, notiData[0]?.count, "blue", null],
    [notiData[1]?.name, notiData[1]?.count, "orange", null],
    [notiData[2]?.name, notiData[2]?.count, "red", null],
    [notiData[3]?.name, notiData[3]?.count, "green", null],
  ];
  // console.log("f", filteredIssues);

  function formatTo12Hour(dateString) {
    const date = new Date(dateString);
    let hours = date.getHours();
    const minutes = date.getMinutes();
    const ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    const strMinutes = minutes < 10 ? "0" + minutes : minutes;
    const strTime = hours + ":" + strMinutes + " " + ampm;
    return strTime;
  }

  useEffect(() => {
    DateTypeFunction();
  }, [evntschooseDate]);

  // event api function
  const DateTypeFunction = () => {
    const user = JSON.parse(localStorage.getItem("user"));
    // console.log(user, "user");

    let data = {
      loginId: user?.id,
      // startDate: "2024-07-03",
      startDate: evntschooseDate,
    };

    API.postMethodNotoken("api/DateClusterReservations", "post", data)
      .then((response) => {
        // console.log(response);

        return response.json();
      })

      .then((res) => {
        // return

        // console.log(res.bookings,"asdf")
        setDateEvents(res.bookings);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  //condition based route for event
  const GuestLocation = (data) => {
    // console.log(data.id,"cde")
    window.location.href = `/discover/booking-guest/${data.id}`;
  };

  //condition based route for event
  const BookingLocation = (data) => {
    // console.log(data.id,"cd")
    window.location.href = `/discover/booking-details/${data.id}`;
  };

  //event list ascending order sorting
  const sortedDateEvents = [...DateEvents].sort(
    (a, b) => new Date(a.start_date) - new Date(b.start_date)
  );

  const [showAllVisitors, setShowAllVisitors] = useState(false);
  const [showAllMeetings, setShowAllMeetings] = useState(false);

  const handleViewMoreVisitors = () => {
    setShowAllVisitors(!showAllVisitors);
  };

  const handleViewMoreMeetings = () => {
    setShowAllMeetings(!showAllMeetings);
  };

  const visitorEvents = sortedDateEvents.filter(
    (event) => event?.guest_added_by
  );
  const meetingEvents = sortedDateEvents.filter(
    (event) => !event?.guest_added_by
  );

  const visibleVisitorEvents = showAllVisitors
    ? visitorEvents
    : visitorEvents.slice(0, 3);
  const visibleMeetingEvents = showAllMeetings
    ? meetingEvents
    : meetingEvents.slice(0, 3);

  //events and charts tabs content
  const content = [
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-around",
          padding: 0,
        }}
      >
        <div style={{ textAlign: "center", width: "48%", fontSize: "16px" }}>
          {" "}
          {evntschooseDate === today ? <b>Today</b> : "  "}{" "}
        </div>
        <div style={{ textAlign: "center", width: "48%" }}>
          <input
            type="date"
            className="form-control "
            style={{ margin: 10, width: "50%" }}
            value={evntschooseDate}
            autoComplete="off"
            onChange={(e) => setevntschooseDate(e.target.value)}
          />
        </div>
      </div>
      {/* {evntschooseDate??evntschooseDate} */}

      <div>
        {visitorEvents.length === 0 && meetingEvents.length === 0 && (
          <div>No events available.</div>
        )}
        {visitorEvents.length > 0 && (
          <div>
            <b>Visitor</b>
            <ul>
              {visibleVisitorEvents.map((event, index) => (
                <li
                  key={`visitor-${index}`}
                  className="date_events"
                  onClick={() => GuestLocation(event)}
                >
                  <span>
                    {`A ${event.property_details.property_name} has a guest ${
                      event.roles
                    } in the slot of ${formatTo12Hour(event.start_date)}`}
                  </span>
                </li>
              ))}
            </ul>
            {/* {visitorEvents.length > 3 && (
            !showAllVisitors ? (
              <span className="view_more_btn" onClick={handleViewMoreVisitors}>View More Visitors</span>
            ) : (
              <span className="view_more_btn"  onClick={handleViewMoreVisitors}>View Less Visitors</span>
            )
          )} */}
            {visitorEvents.length > 3 && (
              <Link className="view_more_btn" to="/visitor-management ">
                {" "}
                View More visitors{" "}
              </Link>
            )}
            {/* visitor-management */}
            {/* book */}
          </div>
        )}

        {meetingEvents.length > 0 && (
          <div>
            <b>Meeting Room Booking</b>
            <ul>
              {visibleMeetingEvents.map((event, index) => (
                <li
                  key={`meeting-${index}`}
                  className="date_events"
                  onClick={() => BookingLocation(event)}
                >
                  <span>
                    {`A ${event.property_details.property_name} was booked by ${
                      event.roles
                    } in the slot of ${formatTo12Hour(
                      event.start_date
                    )} to ${formatTo12Hour(event.end_date)}`}
                  </span>
                </li>
              ))}
            </ul>
            {/* {meetingEvents.length > 3 && (
            !showAllMeetings ? (
              <span className="view_more_btn"  onClick={handleViewMoreMeetings}>View More Meetings</span>
            ) : (
              <span  className="view_more_btn" onClick={handleViewMoreMeetings}>View Less Meetings</span>
            )
          )} */}
            {meetingEvents.length > 3 && (
              <Link className="view_more_btn" to="/book ">
                {" "}
                View More booking{" "}
              </Link>
            )}
          </div>
        )}
      </div>
    </>,
    <>
      <Charts key={1} type={"PieChart"} data={pieChartData} />
      <Charts key={2} type={"BarChart"} data={barChartData} />
      <div style={floatingVersionStyles}>Version 1.1</div>
    </>,
  ];

  return (
    <>
      {actualLoading ? (
        <>
          <Container className="d-flex justify-content-center align-items-center h-100 w-100">
            <div className="lds-spinner">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </Container>
        </>
      ) : (
        <Row className=" mx-0 p-0">
          <Col
            xs={12}
            sm={12}
            md={12}
            lg={isFullScreen ? 12 : 8}
            xl={isFullScreen ? 12 : 8}
            xxl={isFullScreen ? 12 : 8}
          > 
            {loading && (
              <Container className="d-flex justify-content-center align-items-center h-100 w-100">
                <div className="lds-spinner">
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
              </Container>
            )}

            <TableComponent
              key={1}
              tableData={filteredIssues}
              isLoading={isLoading}
              setIsLoading={setIsLoading}
            />
          </Col>
          {!isFullScreen && (
            <Col xs={12} sm={12} md={12} lg={4} xl={4} xxl={4}>
              <div className="tabs-container cct_tab">
                <div
                  className="tabs"
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    padding: "0",
                    borderRadius: "4px",
                  }}
                >
                  {tabs.map((tab, index) => (
                    <div
                      style={{
                        textAlign: "center",
                        width: "48%",
                        background: "#ffd43b",
                        color: "#fff",
                        cursor: "pointer",
                        fontSize: "15px",
                        padding: "6px",
                        fontWeight: 500,
                        textTransform: "uppercase",
                        borderRadius: "6px",
                      }}
                      key={index}
                      className={`tab-button ${
                        activeTab === index ? "active" : ""
                      }`}
                      onClick={() => setActiveTab(index)}
                    >
                      {tab}
                    </div>
                  ))}
                </div>
                <div className="tab-content">{content[activeTab]}</div>
              </div>
            </Col>
          )}
        </Row>
      )}
    </>
  );
};

export default Sections;
